<template>
  <b-table class="workflow-report-activity-table"
           striped
           hover
           :sticky-header="headerStyle"
           :fields="fields"
           :items="filteredResults"
           :busy="loading"
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
  >
    <template v-slot:head(description)="data">
      <span>{{ data.label }}<table-filter-header :ref="`${data.label}-filter`" v-model="descriptionFilter" @input="onDescriptionFiltered" :show="showFilter" @cleared="onDescriptionFilterClear" @hidden="onShowFilter(data.label)" @shown="onShowFilter(data.label)" /></span>
    </template>

    <template v-slot:cell(description)="{ item }">
      <span>{{ item.description }}</span>
      <b-link
        v-if="item.links !== null && item.links.report.length > 0"
        @click="onViewReplacementReportClicked(item)"
        class="vertical-align d-inline-flex align-items-center"
      >
        <b-icon icon="link45deg" scale="1.4" class="ml-2" />
      </b-link>

      <b-link
        v-else-if="item.links !== null && item.links.document.length > 0"
        @click="onViewLinkedDocumentClicked(item)"
        class="vertical-align d-inline-flex align-items-center"
      >
        <b-icon icon="link45deg" scale="1.4" class="ml-2" />
      </b-link>

      <b-link
        v-else-if="item.links !== null && item.links.dataLoad.length > 0"
        @click="onViewLinkedDataLoadClicked(item)"
        class="vertical-align d-inline-flex align-items-center"
      >
        <b-icon icon="link45deg" scale="1.4" class="ml-2" />
      </b-link>
    </template>

    <template v-slot:cell(timestamp)="{ item }">
      {{ item.timestamp | datetime }}
    </template>

    <template v-slot:cell(status)="{ item }">
      <workflow-report-activity-status :item="item" />
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { WorkflowReportActivityResponse } from '@/api/responses/workflow/workflow-report-activity-response';
import WorkflowReportActivityStatus from './workflow-report-activity-status.vue';
import TableFilterHeader from '@/components/table/table-header-filter.vue';
import { namespace } from 'vuex-class';
import { DateTime } from 'luxon';
import debounce from 'lodash.debounce';
import { FindWorkflowResponse } from '@/api/responses/workflow/find-workflow-response';
import { kebabcase } from '@/utilities/text.utils';
import { FindDocumentResponse } from '@/api/responses/document-library/find-document-response';
import { FoundDataLoadResponse } from '@/api/responses/data/found-data-load-response';
import { date } from '@/filters/date';
import { ReportGroup, Tenant } from '@/store/tenant/state';
import { WorkflowDate } from '@/store/workflow/state';

const environmentModule = namespace('environment');
const tenantModule = namespace('tenant');
const workflowModule = namespace('workflow');

@Component({
  components: {
    WorkflowReportActivityStatus,
    TableFilterHeader,
  },
})
export default class WorkflowReportActivityTable extends Mixins(BvToastMixin) {
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @tenantModule.Getter private tenants!: Array<Tenant>;
  @tenantModule.Getter('selectedReportGroup') private currentReportGroup!: ReportGroup;
  @workflowModule.Getter('selectedValuationDate') private currentValuationDate!: WorkflowDate | null;
  @Prop({ type: Array, required: true }) public activity!: Array<WorkflowReportActivityResponse>;
  @Prop({ type: Boolean, required: true }) public loading!: boolean;

  private sortBy: string | null = null;
  private sortDesc: string | null = null;
  private descriptionFilter: string | null = null;
  private showFilter: boolean = false;

  public get fields(): Array<{ key: string, label: string, class?: Array<string> | string, sortable?: boolean, formatter?: Function }> {
    return [
      { key: 'status', label: '', class: ['col-auto'] },
      { key: 'description', label: 'Description', class: ['col-auto is-breakable'] },
      { key: 'timestamp', label: 'Date', class: ['col-3'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); } }
    ];
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public get filteredResults(): Array<WorkflowReportActivityResponse> {
    if (this.activity == null || this.activity.length < 1) {
      return [];
    }

    if (!this.descriptionFilter) {
      return this.activity;
    }

    return this.activity
      .filter((a) => a.description!.toLowerCase().includes(this.descriptionFilter!.trim().toLowerCase()));
  }

  private onShowFilter(id: string): void {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      this.$nextTick(() => (this.$refs[`${id}-filter`] as TableFilterHeader).focus());
    }
  }

  private onDescriptionFiltered = debounce(this.onDescriptionFilteredInternal, 250);
  private onDescriptionFilteredInternal(value: string | null) {
    this.descriptionFilter = value;
  }

  private onDescriptionFilterClear(): void {
    this.descriptionFilter = null;
  }

  private async onViewReplacementReportClicked(item: WorkflowReportActivityResponse): Promise<void> {
    const { option, reportId } = this.$route.params;

    if (reportId !== undefined && item.links.report[0] !== Number(reportId)) {
      const result = await this.$store.dispatch('workflow/findWorkflowReportAsync', item.links.report[0]) as FindWorkflowResponse;

      if (result === null) {
        return;
      }

      const { ...params } = this.$route.params;

      if (kebabcase(result.state) !== option) {
        params.option = kebabcase(result.state);
      }

      await this.$router.push({ name: 'workflow', params: { ...params, reportId: item.links.report[0].toString() } });
    }
  }

  private async onViewLinkedDocumentClicked(item: WorkflowReportActivityResponse): Promise<void> {
    const documentId = item.links.document[0];
    const result = await this.$store.dispatch('documentLibrary/findDocumentAsync', documentId) as FindDocumentResponse;

    if (result === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    await this.$router.push({ name: 'document-library', params: { tenant: this.$route.params.tenant, reportGroup: kebabcase(result.reportGroup), documentId: documentId.toString() } });
  }

  private async onViewLinkedDataLoadClicked(item: WorkflowReportActivityResponse): Promise<void> {
    const dataLoadId = item.links.dataLoad[0].toString();

    const tryFindAsync = async (reportGroupId: number | null = null, valuationDate: string | null = null) => {
      try {
        const result = await this.$store.dispatch('data/findDataLoadAsync', { dataLoadId: dataLoadId, reportGroupId: reportGroupId, valuationDate: valuationDate }) as FoundDataLoadResponse;

        return result;
      } catch (e) {
        return null;
      }
    };

    try {
      let result = await tryFindAsync(this.currentReportGroup.id, this.currentValuationDate?.displayDate);

      if (result === null) {
        result = await tryFindAsync(null);
      }

      if (result === null) {
        throw new Error(`Cannot find data load '${dataLoadId}'`);
      }

      const currentTenant = this.currentTenant;
      const params = { ...this.$route.params };
      const currentReportGroup = this.currentReportGroup;

      if (currentTenant.id !== result.tenantId) {
        const matchingTenant = this.tenants.find((t) => t.id === result!.tenantId) || null;

        if (matchingTenant === null) {
          throw new Error('No matching tenant found.');
        }

        params.tenant = kebabcase(matchingTenant.name);
      }

      if (currentReportGroup.id !== result.reportGroupId) {
        params.reportGroup = kebabcase(result.reportGroup);
      }

      const currentValuationDate = this.currentValuationDate;

      if (currentValuationDate!.valuationDate !== result.valuationDate) {
        params.valuationDate = kebabcase(date(result.displayDate, result.dateFormat));
      }

      if (params.dataLoadId !== dataLoadId) {
        params.dataLoadId = dataLoadId;
      }

      await this.$store.commit('data/reset');
      await this.$router.push({ name: 'data', params: { ...params, tab: 'data-loads', dataLoadId: dataLoadId.toString() } });
    } catch (e) {
      this.showErrorToast(`Could not find data load '${dataLoadId}'. Please try again.`);
    }
  }
}
</script>

<style scoped>
.vertical-align {
  vertical-align: middle;
}
</style>
