import { GetterTree } from 'vuex';
import { DocumentLibraryFilters, State } from '@/store/document-library/state';
import { DateTime } from 'luxon';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public current = (state: State) => {
    return state.current;
  }

  public entities = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.entities;
  }

  public valuationDates = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.dates;
  }

  public regions = (state: State) => {
    if (state.current == null) {
      return [];
    }

    if (state.current.searchType === 'StaticDocuments') {
      if (state.currentLanguage !== null) {
        return state.current.regions.filter((r) => r.languageId === state.currentLanguage?.id);
      }
    }

    return state.current.regions;
  }

  public languages = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.languages;
  }

  public documentTypes = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.documentTypes;
  }

  public insertTypes = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.insertTypes;
  }

  public commentaryTypes = (state: State) => {
    if (state.current == null) {
      return [];
    }

    return state.current.commentaryTypes;
  }

  public toValuationDates = (state: State) => {
    if (state.current == null) {
      return [];
    }

    if (state.currentValuationDateFrom === null) {
      return this.valuationDates(state);
    }

    return this.valuationDates(state).filter((date) => {
      return DateTime.fromISO(state.currentValuationDateFrom!.valuationDate) <= DateTime.fromISO(date.valuationDate);
    });
  }

  public currentValuationDate = (state: State) => {
    return state.currentValuationDate;
  }

  public currentValuationDateFrom = (state: State) => {
    return state.currentValuationDateFrom;
  }

  public currentValuationDateTo = (state: State) => {
    return state.currentValuationDateTo;
  }

  public currentRegion = (state: State) => {
    return state.currentRegion;
  }

  public currentEntity = (state: State) => {
    return state.currentEntity;
  }

  public currentLanguage = (state: State) => {
    return state.currentLanguage;
  }

  public currentCommentaryType = (state: State) => {
    return state.currentCommentaryType;
  }

  public currentDocumentType = (state: State) => {
    return state.currentDocumentType;
  }

  public currentInsertType = (state: State) => {
    return state.currentInsertType;
  }

  public documents = (state: State) => {
    return state.pages.flatMap((p) => p.documents);
  }

  public totalDocuments = (state: State) => {
    if (state.pages.length < 1) {
      return 0;
    }

    return state.pages[0].total;
  }

  public pages = (state: State) => {
    return state.pages;
  }

  public currentDocument = (state: State) => {
    if (state.selectedDocumentId === null) {
      return null;
    }

    return this.documents(state).find((d) => d.id === state.selectedDocumentId) || null;
  }

  public currentReportGroup = (state: State) => {
    return state.currentReportGroup;
  }

  public isDownloading = (state: State) : boolean => {
    return state.downloadingDocuments;
  }

  public filters = (state: State): DocumentLibraryFilters => {
    return state.filters;
  }
}
