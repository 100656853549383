<template>
  <div class="container-fluid">
    <div class="row mt-4 pb-5">
      <div class="col-8">
        <div v-if="ticket !== null" class="support-ticket">
          <div class="title row align-items-center no-gutters">
            <div class="col-auto">
              <b-button type="submit" size="sm" variant="outline-primary" :to="{ name: 'support', params: { ...$route.params, status: kebabcase(originalStatus) } }"><b-icon icon="chevron-left" /></b-button>
            </div>
            <editable-support-ticket-title :key="ticket.id" :ticket="ticket" @saved="onSubjectSaved" />
            <div class="col-auto pl-0">
              <b-dropdown variant="link" v-if="currentUser.roles.administrator">
                <template v-slot:button-content>
                  <b-icon icon="person-plus-fill" /> {{ ticket.assignedTo || 'Unassigned' }}
                </template>
                <b-dropdown-item v-for="agent in agents" :key="agent.id" :value="agent.id" :title="`Assigned to ${agent.name}`" @click="onAssignClicked(agent)">{{ agent.name }}</b-dropdown-item>
              </b-dropdown>
              <span style="padding: 0.375rem 0.75rem;" v-else>
                <b-icon icon="person-fill" />{{ ticket.assignedTo || 'Unassigned' }}
              </span>
            </div>
            <div class="actions col-auto ml-auto">
              <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
                <b-button-group>
                  <b-dropdown :variant="statusVariant" title="Change Status" :text="ticket.status" v-if="currentUser.roles.administrator">
                    <b-dropdown-item v-if="ticket.actions.canReturnToSupport" :title="'Change status to support investigating'" @click="placeTicketUnderInvestigation">With us</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canReturnToCustomer" :title="'Change status to waiting for client'" @click="ticketWaitingOnCustomer">Waiting on client</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canPutOnHold" :title="'Change status to on hold'" @click="placeTicketOnHold">On hold</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canMoveToDevelopment" :title="'Change status to requires development'" @click="placeTicketInDevelopment">Requires development</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.isReadyForTesting" title="Change status to ready for testing" @click="ticketReadyForTesting">Ready for testing</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.isReadyForRelease" title="Change status to ready for release" @click="ticketReadyForRelease">Ready for release</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canMarkAsResolved" :title="'Resolved'" @click="resolveTicket">Resolved</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canClose" :title="'Close ticket'" @click="closeTicket">Close</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canReopen" :title="'Reopen ticket'" @click="reopenTicket">Reopen</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown :variant="statusVariant" title="Change Status" :text="ticket.status" v-else>
                    <b-dropdown-item v-if="ticket.actions.canReturnToSupport" :title="'Change status to support investigating'" @click="placeTicketUnderInvestigation">Waiting on support</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canReturnToCustomer" :title="'Change status to waiting for client'" @click="ticketWaitingOnCustomer">With us</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canPutOnHold" :title="'Change status to on hold'" @click="placeTicketOnHold">On hold</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canMoveToDevelopment" :title="'Change status to requires development'" @click="placeTicketInDevelopment">Requires development</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.isReadyForTesting" title="Change status to ready for testing" @click="ticketReadyForTesting">Ready for testing</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.isReadyForRelease" title="Change status to ready for release" @click="ticketReadyForRelease">Ready for release</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canMarkAsResolved" :title="'Resolved'" @click="resolveTicket">Resolved</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canClose" :title="'Close ticket'" @click="closeTicket">Close</b-dropdown-item>
                    <b-dropdown-item v-if="ticket.actions.canReopen" :title="'Reopen ticket'" @click="reopenTicket">Reopen</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown :variant="priorityVariant" title="Change Priority" v-if="currentUser.roles.administrator">
                    <template v-slot:button-content>
                      <b-icon icon="exclamation-circle" />
                    </template>
                    <b-dropdown-item v-for="priority in priorities" :key="priority.id" @click="onTicketPriorityChanged(priority.id)">{{ priority.name }}</b-dropdown-item>
                  </b-dropdown>
                  <b-button :variant="priorityVariant" :title="priorityName" v-else><b-icon icon="exclamation-circle" /></b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </div>
          <div style="padding: 6px;margin-left: 39px;" v-if="ticket.ccEmailAddress">
            <b-icon icon="person-plus-fill" /><span style="margin-left: 5px;">{{ ticket.ccEmailAddress }}</span>
          </div>
          <div class="body">
            <editable-support-ticket-description :key="ticket.id" :ticket="ticket" @saved="onDescriptionSaved" />
          </div>

          <div class="reply">
            <b-tabs id="reply" class="reply-tabs">
              <b-tab title="Reply">
                <b-form-textarea
                  id="textarea"
                  placeholder="Write your reply here"
                  rows="6"
                  v-model="reply"
                  class="border-bottom-0"
                  @paste="onPaste"
                ></b-form-textarea>
                <div>
                  <div class="attachment-upload-list border border-top-0">
                    <div class="attachment-upload-list-item px-3 py-2" v-for="attachment in attachmentfiles" :key="attachment.name">
                      <b-icon scale="1.2" icon="file-earmark" />
                      {{ attachment.name }}
                      (<file-size :bytes="attachment.size" />)
                      <b-button type="button" variant="link" size="sm" @click="onRemoveAttachmentFile(attachment)">
                        <b-icon icon="x" variant="danger" />
                      </b-button>
                    </div>
                  </div>
                  <b-form-file class="support-ticket-attachment-file-upload" v-model="files" multiple placeholder="Drag &amp; drop files here, paste files directly or click here to browse" drop-placeholder="Drop file here..." @input="onAttachmentAdded"></b-form-file>
                </div>

                <div class="text-right pt-2">
                  <b-button type="submit" variant="primary" :disabled="submittingReply" @click="onReplyClicked(reply)">
                    <b-icon icon="reply-fill" v-if="!submittingReply" /> <span v-if="!submittingReply">Reply</span>
                    <b-icon icon="arrow-clockwise" animation="spin" v-else /> <span v-if="submittingReply">Submitting</span>
                  </b-button>
                </div>
              </b-tab>
              <b-tab title="Note" v-if="isAdministrator">
                <b-form-textarea
                  id="textarea-note"
                  placeholder="Write your note here"
                  rows="6"
                  no-resize
                  v-model="note"
                  class="border-bottom-0 note-editor"
                ></b-form-textarea>
                <div>
                  <div class="attachment-upload-list border border-top-0">
                  </div>
                  <div class="custom-file b-form-file support-ticket-attachment-file-upload">
                    <input type="hidden" class="custom-file-input fake-custom-file-input" />
                    <label class="custom-file-label"></label>
                  </div>
                </div>

                <div class="text-right pt-2">
                  <b-button type="submit" variant="primary" :disabled="submittingNote" @click="onNoteClicked(note)">
                    <span v-if="!submittingNote">Add note</span>
                    <b-icon icon="arrow-clockwise" animation="spin" v-else /> <span v-if="submittingNote">Submitting</span>
                  </b-button>
                </div>
              </b-tab>
            </b-tabs>
          </div>

          <b-tabs id="details" class="workflow-steps mt-4">
            <b-tab :title="`Messages (${messageCount})`">
              <support-ticket-message-list v-if="messages !== null" />
            </b-tab>
            <b-tab :title="`Notes (${noteCount})`" v-if="isAdministrator">
              <support-ticket-note-list v-if="notes !== null" />
            </b-tab>
            <b-tab :title="`Attachments (${attachmentCount})`">
              <support-ticket-attachment-list v-if="attachments !== null" />
            </b-tab>
            <b-tab :title="`Activity (${activityCount})`">
              <support-ticket-activity-list v-if="activity !== null" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-4" v-if="ticket !== null">
        <div class="p-4 border" style="background: rgb(248, 249, 250);">
          <div class="col">
            <b-form-group
              id="input-group-1"
              label="Client:"
              label-for="input-tenant"
              label-cols="3"
              class="align-items-center"
            >
              <div>{{ tenantName }}</div>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Priority:"
              label-for="input-priority"
              label-cols="3"
              class="align-items-center"
            >
              <b-form-select v-model="ticket.priorityId" v-if="currentUser.roles.administrator">
                <b-form-select-option v-for="priority in priorities" :key="priority.id" :value="priority.id">{{ priority.name }}</b-form-select-option>
              </b-form-select>

              <div v-else>{{ priorityName }}</div>
            </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Issue Type:"
              label-for="input-issue-type"
              label-cols="3"
              class="align-items-center"
            >
              <b-form-select v-model="ticket.issueTypeId">
                <b-form-select-option v-for="type in issueTypes" :key="type.id" :value="type.id">{{ type.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-5"
              label="Category:"
              label-for="input-category"
              label-cols="3"
              class="align-items-center"
            >
              <b-form-select v-model="ticket.categoryId">
                <b-form-select-option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-6"
              label="Report Group(s):"
              label-for="input-category"
              label-cols="3"
              class="align-items-center"
            >
              <b-form-select v-model="ticket.reportGroup">
                <b-form-select-option :value="'All'">All</b-form-select-option>
                <b-form-select-option v-for="group in reportGroups" :key="group.id" :value="group.name">{{ group.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <validation-provider name="valuationDate" rules="" v-slot="{ errors }" tag="div">
              <b-form-group
                id="input-group-6"
                label="Valuation Date:"
                label-for="input-valuation-date"
                label-cols="3"
              >
                <b-form-select v-model="ticket.valuationDate" :state="errors[0] ? false : null">
                  <b-form-select-option :value="null">None</b-form-select-option>
                  <b-form-select-option v-for="date in dates" :key="date.valuationDate" :value="date.valuationDate">{{ date.displayDate | date }}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback v-if="errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider name="report id" rules="numeric" v-slot="{ errors }" tag="div">
              <b-form-group
                id="input-group-7"
                label="Report Id:"
                label-for="input-report-id"
                label-cols="3"
              >
                <div class="row">
                  <div class="col">
                    <b-form-input
                      id="input-report-id"
                      name="reportId"
                      v-model="ticket.reportId"
                      :state="errors[0] ? false : null"
                    ></b-form-input>
                  </div>
                  <div class="col-auto pl-0" v-if="ticket.reportId">
                    <b-btn variant="light" :disabled="!ticket.reportId" @click="openWorkflowReportInNewTab">
                      <b-icon icon="link45deg" scale="1.5"></b-icon>
                    </b-btn>
                  </div>
                </div>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="cc mail" :rules="{ regex: emailRegex }" v-slot="{ errors }" tag="div">
              <b-form-group
                id="input-group-7"
                label="CC Mail Address:"
                label-for="cc-mail"
                label-cols="3"
              >
                <b-form-input
                  id="cc-mail"
                  name="cc mail"
                  v-model="ticket.ccEmailAddress"
                  :state="errors[0] ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback>{{ emailValidationErrorMsg }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              id="input-group-2"
              label="Raised by:"
              label-for="input-raisedby"
              label-cols="3"
            >
              <b-form-select v-model="ticket.createdBy">
                <b-form-select-option v-for="user in users" :key="user.id" :value="user.name">{{ user.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <div class="text-right pt-2">
              <b-button type="submit" variant="primary" @click="onUpdateTicketClicked">
                Update
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <support-ticket-leave-confirmation-dialog ref="confirmDialog" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { SupportConfiguration, Category, IssueType, Priority, SupportTicketDetails, Agent, SupportTicketAttachment, SupportTicketMessage, SupportTicketActivityEntryGroup, User, ValuationDate } from '../store/support/state';
import { Tenant, ReportGroup } from '@/store/tenant/state';
import { UserResponse } from '../api/responses/user/user-response';
import { kebabcase } from '@/utilities/text.utils';
import SupportTicketMessageList from '@/components/support/support-ticket-message-list.vue';
import SupportTicketNoteList from '@/components/support/support-ticket-note-list.vue';
import SupportTicketActivityList from '@/components/support/support-ticket-activity-list.vue';
import SupportTicketAttachmentList from '@/components/support/support-ticket-attachment-list.vue';
import EditableSupportTicketTitle from '@/components/support/editiable-support-ticket-title.vue';
import EditableSupportTicketDescription from '@/components/support/editiable-support-ticket-description.vue';
import SupportTicketLeaveConfirmationDialog from '@/components/support/support-ticket-leave-confirmation-dialog.vue';
import FileSize from '@/components/utils/file-size.vue';
import * as Sentry from '@sentry/browser';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { validEmailRegex, invalidEmailErrorMsg } from '../utilities/email';
import { FindWorkflowResponse } from '../api/responses/workflow/find-workflow-response';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { date } from '@/filters/date';
import { FileUtils } from '@/utilities/file.utils';

const supportModule = namespace('support');
const tenantModule = namespace('tenant');
const userModule = namespace('user');

@Component({
  components: {
    SupportTicketMessageList,
    SupportTicketNoteList,
    SupportTicketActivityList,
    SupportTicketAttachmentList,
    EditableSupportTicketTitle,
    EditableSupportTicketDescription,
    SupportTicketLeaveConfirmationDialog,
    ValidationProvider,
    ValidationObserver,
    FileSize,
  },
})
export default class SupportTicket extends Mixins(BvToastMixin) {
  @tenantModule.Getter tenants!: Array<Tenant>;
  @supportModule.Getter current!: SupportConfiguration | null;
  @supportModule.Getter categories!: Array<Category>;
  @supportModule.Getter issueTypes!: Array<IssueType>;
  @supportModule.Getter priorities!: Array<Priority>;
  @supportModule.Getter dates!: Array<ValuationDate>;
  @supportModule.Getter agents!: Array<Agent>;
  @supportModule.Getter ticket!: SupportTicketDetails | null;
  @supportModule.Getter messages!: Array<SupportTicketMessage>;
  @supportModule.Getter notes!: Array<SupportTicketMessage>;
  @supportModule.Getter attachments!: Array<SupportTicketAttachment>;
  @supportModule.Getter activity!: Array<SupportTicketActivityEntryGroup>;
  @supportModule.Getter usersForTenant!: (tenantId: number) => Array<User>;
  @tenantModule.Getter reportGroupsForTenant!: (tenantId: number) => Array<ReportGroup>;
  @userModule.Getter('current') currentUser!: UserResponse;
  @tenantModule.Getter('current') currentTenant!: TenantResponse;

  kebabcase = kebabcase;

  originalStatus: string | null = null;
  originalTicket: SupportTicketDetails | null = null;
  reply: string | null = null;
  note: string | null = null;
  submittingReply = false;
  submittingNote = false;
  attachmentfiles: Array<File> = [];
  files: Array<File> = [];
  emailRegex: RegExp = validEmailRegex;
  emailValidationErrorMsg = invalidEmailErrorMsg;

  public get tenantName(): string | null {
    if (this.ticket === null) {
      return null;
    }

    return this.tenants.find((t) => t.id === this.ticket!.tenantId)?.name || 'Unknown';
  }

  get reportGroups(): Array<ReportGroup> {
    if (this.ticket === null || this.ticket.tenantId === null) {
      return [];
    }

    return this.reportGroupsForTenant(this.ticket!.tenantId!);
  }

  get statusVariant(): string {
    if (this.ticket === null) {
      return 'outline-primary';
    }

    switch (this.ticket.status) {
      case 'New':
        return 'success';
      case 'Support Investigating':
        return 'warning';
      case 'Waiting for Client':
        return 'info';
      case 'In Development':
        return 'warning';
      case 'On Hold':
        return 'warning';
      case 'Closed':
        return 'danger';
      default:
        return 'outline-primary';
    }
  }

  get messageCount(): number {
    if (this.messages === null) {
      return 0;
    }

    return this.messages.length;
  }

  get noteCount(): number {
    if (this.notes === null) {
      return 0;
    }

    return this.notes.length;
  }

  get activityCount(): number {
    if (this.activity === null) {
      return 0;
    }

    return this.activity.reduce((prev, current) => {
      return prev + current.entries.length;
    }, 0);
  }

  get attachmentCount(): number {
    if (this.attachments === null) {
      return 0;
    }

    return this.attachments.length;
  }

  get priorityVariant(): string {
    if (this.ticket === null) {
      return 'outline-primary';
    }

    switch (this.ticket.priorityId) {
      case 1:
        return 'critical';
      case 2:
        return 'danger';
      case 3:
        return 'warning';
      case 4:
        return 'success';
      default:
        return 'info';
    }
  }

  get priorityName(): string {
    if (this.ticket === null) {
      return 'Unknown';
    }

    return this.priorities.find((p) => p.id === this.ticket!.priorityId)?.name || 'Unknown';
  }

  get users(): Array<User> {
    if (this.ticket === null) {
      return [];
    }

    return this.usersForTenant(this.ticket.tenantId!);
  }

  get isAdministrator(): boolean {
    return this.currentUser.roles.administrator || false;
  }

  beforeRouteLeave(to: string, from: string, next: Function) {
    if (!this.reply) {
      next();
    } else {
      this.showConfirmDialog().then((confirmed: boolean) => {
        if (confirmed) {
          next();
        } else {
          next(false);
        }
      });
    }
  }

  showConfirmDialog() {
    return (this.$refs.confirmDialog as any).show();
  }

  public async mounted(): Promise<void> {
    if (this.current === null) {
      await this.$store.dispatch('support/getSupportAsync');
    }

    try {
      await this.loadTicketAsync(Number(this.$route.params.id));
    } catch (e) {
      await this.$router.replace({ name: 'not-found' });
      return;
    }

    const messages = this.loadMessagesAsync(Number(this.$route.params.id));
    const notes = this.loadNotesAsync(Number(this.$route.params.id));
    const activity = this.loadActivityAsync(Number(this.$route.params.id));
    const attachments = this.loadAttachmentsAsync(Number(this.$route.params.id));

    await Promise.all([messages, notes, activity, attachments]);
  }

  async onReplyClicked(reply: string | null): Promise<void> {
    this.submittingReply = true;

    try {
      if (reply !== null && reply.length > 0) {
        await this.$store.dispatch('support/submitTicketReplyAsync', { id: this.ticket!.id, message: reply });
      }

      const files = this.attachmentfiles.map((f) => this.$store.dispatch('support/submitTicketAttachmentAsync', { ticketId: this.ticket!.id, file: f }));

      await Promise.all(files);

      this.reply = null;
      this.attachmentfiles = [];

      this.showSuccessToast('Reply successfully submitted.');

      await this.loadMessagesAsync(this.ticket!.id);

      if (files.length > 0) {
        await this.loadAttachmentsAsync(this.ticket!.id);
      }

      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not submit reply. Please try again.');
    } finally {
      this.submittingReply = false;
    }
  }

  async onNoteClicked(reply: string | null): Promise<void> {
    this.submittingNote = true;

    try {
      if (reply !== null && reply.length > 0) {
        await this.$store.dispatch('support/submitTicketNoteAsync', { id: this.ticket!.id, message: reply });
      }

      this.note = null;
      this.attachmentfiles = [];

      this.showSuccessToast('Note successfully submitted.');

      await this.loadNotesAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not submit note. Please try again.');
    } finally {
      this.submittingNote = false;
    }
  }

  async onTicketPriorityChanged(priorityId: number): Promise<void> {
    try {
      if (this.originalTicket!.priorityId === priorityId) {
        return;
      }

      const originalPriority = this.priorities.find((p) => p.id === this.originalTicket!.priorityId)!;
      const priority = this.priorities.find((p) => p.id === priorityId)!;
      await this.$store.dispatch('support/amendTicketPriorityAsync', { id: this.ticket!.id, fromPriorityId: originalPriority.id, fromPriority: originalPriority.name, toPriorityId: priority.id, toPriority: priority.name });

      this.ticket!.priorityId = priority.id;
      this.ticket!.priority = priority.name;

      this.originalTicket!.priorityId = priority.id;
      this.originalTicket!.priority = priority.name;

      this.showSuccessToast(`Ticket priority updated to ${priority.name}.`);

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket priority. Please try again.');
    }
  }

  async onUpdateTicketClicked(): Promise<void> {
    try {
      if (this.originalTicket!.priorityId !== this.ticket!.priorityId) {
        const originalPriority = this.priorities.find((p) => p.id === this.originalTicket!.priorityId)!;
        const priority = this.priorities.find((p) => p.id === this.ticket!.priorityId)!;
        await this.$store.dispatch('support/amendTicketPriorityAsync', { id: this.ticket!.id, fromPriorityId: originalPriority.id, fromPriority: originalPriority.name, toPriorityId: priority.id, toPriority: priority.name });

        this.ticket!.priorityId = priority.id;
        this.ticket!.priority = priority.name;

        this.originalTicket!.priorityId = priority.id;
        this.originalTicket!.priority = priority.name;
      }

      if (this.originalTicket!.issueTypeId !== this.ticket!.issueTypeId) {
        const originalIssueType = this.issueTypes.find((p) => p.id === this.originalTicket!.issueTypeId)!;
        const issueType = this.issueTypes.find((p) => p.id === this.ticket!.issueTypeId)!;
        await this.$store.dispatch('support/amendTicketIssueTypeAsync', { id: this.ticket!.id, fromIssueTypeId: originalIssueType.id, fromIssueType: originalIssueType.name, toIssueTypeId: issueType.id, toIssueType: issueType.name });

        this.ticket!.issueTypeId = issueType.id;
        this.ticket!.issueType = issueType.name;

        this.originalTicket!.issueTypeId = issueType.id;
        this.originalTicket!.issueType = issueType.name;
      }

      if (this.originalTicket!.categoryId !== this.ticket!.categoryId) {
        const originalCategory = this.categories.find((p) => p.id === this.originalTicket!.categoryId)!;
        const category = this.categories.find((p) => p.id === this.ticket!.categoryId)!;
        await this.$store.dispatch('support/amendTicketCategoryAsync', { id: this.ticket!.id, fromCategoryId: originalCategory.id, fromCategory: originalCategory.name, toCategoryId: category.id, toCategory: category.name });

        this.ticket!.categoryId = category.id;
        this.ticket!.category = category.name;

        this.originalTicket!.categoryId = category.id;
        this.originalTicket!.category = category.name;
      }

      if (this.originalTicket!.reportGroup !== this.ticket!.reportGroup) {
        const fromReportGroup = this.reportGroups.find((rg) => rg.name === this.originalTicket!.reportGroup) || { id: 0, name: 'All' };
        const reportGroup = this.reportGroups.find((rg) => rg.name === this.ticket!.reportGroup) || { id: 0, name: 'All' };
        await this.$store.dispatch('support/amendTicketReportGroupAsync', { id: this.ticket!.id, fromReportGroupId: fromReportGroup.id, fromReportGroup: fromReportGroup.name, toReportGroupId: reportGroup.id, toReportGroup: reportGroup.name });

        this.ticket!.reportGroup = reportGroup.name;
        this.originalTicket!.reportGroup = reportGroup.name;
      }

      if (this.originalTicket!.createdBy !== this.ticket!.createdBy) {
        const fromRaisedBy = this.users.find((u) => u.name === this.originalTicket!.createdBy) || null;
        const toRaisedBy = this.users.find((u) => u.name === this.ticket!.createdBy) || null;

        await this.$store.dispatch('support/amendTicketRaisedByAsync', { id: this.ticket!.id, fromRaisedById: fromRaisedBy!.id, fromRaisedBy: fromRaisedBy!.name, toRaisedById: toRaisedBy!.id, toRaisedBy: toRaisedBy!.name });
      }

      if (this.originalTicket!.valuationDate !== this.ticket!.valuationDate) {
        const fromValuationDate = this.dates.find((d) => d.valuationDate === this.originalTicket!.valuationDate) || null;
        const toValuationDate = this.dates.find((d) => d.valuationDate === this.ticket!.valuationDate) || null;

        await this.$store.dispatch('support/amendTicketValuationDateAsync', { id: this.ticket!.id, from: fromValuationDate, to: toValuationDate });
      }

      if (this.originalTicket!.reportId !== this.ticket!.reportId) {
        const fromReportId = this.originalTicket!.reportId || null;
        const toReportId = this.ticket!.reportId || null;

        await this.$store.dispatch('support/amendTicketReportIdAsync', { id: this.ticket!.id, from: fromReportId, to: toReportId });
      }

      if (this.originalTicket!.ccEmailAddress !== this.ticket!.ccEmailAddress) {
        const fromMail = this.originalTicket!.ccEmailAddress || null;
        const toMail = this.ticket!.ccEmailAddress || null;

        await this.$store.dispatch('support/amendTicketCcmailAsync', { id: this.ticket!.id, from: fromMail, to: toMail });
      }

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');

      Sentry.captureException(e);
    } finally {

    }
  }

  async onAssignClicked(agent: Agent): Promise<void> {
    try {
      if (this.ticket!.assignedToId !== this.originalTicket!.assignedToId) {
        return;
      }

      if (this.ticket!.assignedToId === null) {
        await this.$store.dispatch('support/assignTicketAsync', {
          id: this.ticket!.id,
          assignToUserId: agent.id,
          assignToUser: agent.name,
        });

        await this.$store.dispatch('support/amend', {
          id: this.ticket!.id,
          assignToUserId: agent.id,
          assignToUser: agent.name,
        });
      } else {
        await this.$store.dispatch('support/reassignTicketAsync', {
          id: this.ticket!.id,
          assignFromUserId: this.ticket!.assignedToId,
          assignFromUser: this.ticket!.assignedTo,
          assignToUserId: agent.id,
          assignToUser: agent.name,
        });
      }

      this.ticket!.assignedToId = agent.id;
      this.ticket!.assignedTo = agent.name;

      this.originalTicket!.assignedToId = agent.id;
      this.originalTicket!.assignedTo = agent.name;

      this.showSuccessToast(`Ticket successfully assigned to ${agent.name}.`);

      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast(`Could not assign ticket to ${agent.name}. Please try again.`);
    }
  }

  async loadTicketAsync(id: number): Promise<void> {
    const ticket = await this.$store.dispatch('support/getSupportTicketAsync', id) as SupportTicketDetails | null;

    if (this.originalTicket === null) {
      this.originalStatus = ticket!.state;
    }

    this.originalTicket = { ...ticket! };
  }

  async loadMessagesAsync(id: number): Promise<void> {
    await this.$store.dispatch('support/getSupportTicketMessagesAsync', id);
  }

  async loadNotesAsync(id: number): Promise<void> {
    if (!this.isAdministrator) {
      return;
    }

    await this.$store.dispatch('support/getSupportTicketNotesAsync', id);
  }

  async loadActivityAsync(id: number): Promise<void> {
    setTimeout(async () => {
      await this.$store.dispatch('support/getSupportTicketActivityAsync', id);
    }, 1000);
  }

  async loadAttachmentsAsync(id: number): Promise<void> {
    await this.$store.dispatch('support/getSupportTicketAttachmentsAsync', id);
  }

  async placeTicketUnderInvestigation(): Promise<void> {
    try {
      await this.$store.dispatch('support/placeTicketUnderInvestigationAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async ticketWaitingOnCustomer(): Promise<void> {
    try {
      await this.$store.dispatch('support/ticketWaitingOnCustomerAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async reopenTicket(): Promise<void> {
    try {
      await this.$store.dispatch('support/reopenTicketAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async resolveTicket(): Promise<void> {
    try {
      await this.$store.dispatch('support/resolveTicketAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async closeTicket(): Promise<void> {
    try {
      await this.$store.dispatch('support/closeTicketAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async placeTicketOnHold(): Promise<void> {
    try {
      await this.$store.dispatch('support/placeTicketOnHoldAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async placeTicketInDevelopment(): Promise<void> {
    try {
      await this.$store.dispatch('support/placeTicketInDevelopmentAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async ticketReadyForTesting(): Promise<void> {
    try {
      await this.$store.dispatch('support/ticketReadyForTestingAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async ticketReadyForRelease(): Promise<void> {
    try {
      await this.$store.dispatch('support/ticketReadyForReleaseAsync', this.ticket!.id);

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  onAttachmentAdded(files: Array<File>): void {
    for (const file of files) {
      if (!this.attachmentfiles.some((f) => f.name === file.name)) {
        this.attachmentfiles.push(file);
      }
    }

    this.files = [];
  }

  onRemoveAttachmentFile(file: File): void {
    this.attachmentfiles.splice(this.attachmentfiles.indexOf(file), 1);
  }

  async onSubjectSaved(result: { from: string, to: string }): Promise<void> {
    try {
      await this.$store.dispatch('support/amendTicketSubjectAsync', { id: this.ticket!.id, from: result.from, to: result.to });

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async onDescriptionSaved(result: { from: string, to: string }): Promise<void> {
    try {
      await this.$store.dispatch('support/amendTicketDescriptionAsync', { id: this.ticket!.id, from: result.from, to: result.to });

      this.showSuccessToast('Ticket successfully updated.');

      await this.loadTicketAsync(this.ticket!.id);
      await this.loadActivityAsync(this.ticket!.id);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items || [];

    if (items.length < 1) {
      return;
    }

    const files = new Array<File>();

    for (const index in items) {
      const item = items[index];

      if (item.kind === 'file') {
        let file = item.getAsFile();

        if (file !== null) {
          let i: number = 0;
          const parts: Array<string> = file.name.split('.');
          const ext = parts.pop();
          const name = parts.join('');

          while (this.attachmentfiles.some((f) => f.name === file!.name)) {
            file = new File([file.slice(0, file.size, file.type)], `${name}_${++i}.${ext}`, { type: file.type, lastModified: file.lastModified });
          }

          files.push(file);
        }
      }
    }

    this.onAttachmentAdded(files);
  }

  async openWorkflowReportInNewTab(): Promise<void> {
    const reportId = this.ticket?.reportId;

    if (reportId === null || reportId === undefined) {
      return;
    }

    try {
      const result = await this.$store.dispatch('workflow/findWorkflowReportAsync', this.ticket?.reportId) as FindWorkflowResponse;

      const currentTenant = this.currentTenant;
      let tenant = kebabcase(currentTenant.name);
      if (currentTenant.id !== result.tenantId) {
        const matchingTenant = this.tenants.find((t) => t.id === result!.tenantId) || null;

        if (matchingTenant === null) {
          throw new Error('No matching tenant found.');
        }

        tenant = kebabcase(matchingTenant.name);
      }
      const reportGroup = kebabcase(result.reportGroup);
      const valuationDate = kebabcase(date(result.displayDate, result.dateFormat));
      const option = kebabcase(result.state);
      const id = reportId.toString();

      // build workflow report url
      const host = window.location.host;
      let url = '';

      if (host.includes('localhost')) {
        url = `http://${host}/${tenant}/workflow/${reportGroup}/${valuationDate}/${option}/${id}`;
      } else {
        url = `https://${host}/${tenant}/workflow/${reportGroup}/${valuationDate}/${option}/${id}`;
      }

      FileUtils.previewUrl(url);
    } catch (e) {
      this.showErrorToast(`Could not find report '${this.ticket?.reportId}'.`);
    }
  }
}
</script>
