<template>
  <div class="document-library-documents" :aria-busy="loading">
    <b-table
      class="documents-table"
      ref="table"
      striped
      hover
      selectable
      :sticky-header="headerStyle"
      show-empty
      select-mode="single"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      :tbody-tr-class="applyTableRowClasses"
      :fields="fields"
      :items="filteredDocuments"
      @row-clicked="onRowClicked"
      @row-selected="onRowSelected"
      @sort-changed="onSortChanged"
    >
      <template v-slot:cell(status)="{ item }">
        <locked-status-icon v-if="item.status === 'Locked'" :title="item.statusDescription" />
        <checking-out-status-icon v-if="item.status === 'CheckingOut'" />
        <checking-in-status-icon v-if="item.status === 'CheckingIn'" />
      </template>

      <template v-slot:head(name)="data">
        <span>
          {{ data.label }}

          <table-filter-header
            :ref="`${data.label}-filter`"
            :value="filters.description"
            :show="showFilter"
            @input="onDescriptionFiltered"
            @cleared="onDescriptionFilterClear"
            @hidden="onShowFilter(data.label)"
            @shown="onShowFilter(data.label)"
          />
        </span>
      </template>

      <template v-slot:head(actions)>
        <p v-if="showFilterCount" class="mb-0 text-right">{{ filteredDocuments.length }}/{{ documents.length }}</p>
      </template>

      <template v-slot:cell(valuationDate)="{ item }">
        {{ item.displayDate | date(item.dateFormat || currentDocumentLibrary.dateFormat) }}
      </template>

      <template v-slot:cell(flagged)="{ item }">
        <document-flag-icon :key="`${item.id}_flagged`" :item="item" v-if="item.flagged" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-button
            v-if="item.fileTypePreview"
            :key="`${item.id}_actions_view`"
            size="sm"
            variant="outline-primary"
            :title="getViewFileTooltip(item)"
            @click="onViewDocumentClicked(item)"
          >
            <view-pdf-icon aria-hidden="true" v-if="getFileTypePrievew(item) === 'pdf'" />
            <view-powerpoint-icon aria-hidden="true" v-else-if="getFileTypePrievew(item) === 'pptx'" />
            <view-word-icon aria-hidden="true" v-else-if="getFileTypePrievew(item) === 'docx'" />
            <view-excel-icon aria-hidden="true" v-else-if="['xls', 'xlsx'].includes(getFileTypePrievew(item))" />
            <b-icon icon="eye" aria-hidden="true" v-else />
          </b-button>

          <b-button
            v-if="item.fileTypeDownload && getFileTypeDownload(item) !== 'pdf'"
            :key="`${item.id}_actions_download`"
            size="sm"
            variant="outline-primary"
            :title="getDownloadFileTooltip(item)"
            @click="onDownloadDocumentClicked(item)"
          >
            <!--<download-pdf-icon aria-hidden="true" v-if="getFileTypeDownload(item) === 'pdf'" />-->
            <download-powerpoint-icon aria-hidden="true" v-if="getFileTypeDownload(item) === 'pptx'" />
            <download-word-icon aria-hidden="true" v-else-if="getFileTypeDownload(item) === 'docx'" />
            <download-excel-icon aria-hidden="true" v-else-if="['xls', 'xlsx'].includes(getFileTypeDownload(item))" />
            <b-icon icon="download" aria-hidden="true" v-else />
          </b-button>

          <b-dropdown
            right
            :disabled="!canShowActions(item)"
            :no-caret="true"
            variant="outline-primary"
            size="sm"
            :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }"
          >
            <template v-slot:button-content>
              <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
            </template>
            <b-dropdown-item v-if="item.fileTypePreview!==null" @click="onViewDocumentClicked(item)">
              Preview {{ item.fileTypePreview }}
            </b-dropdown-item>
            <b-dropdown-item v-if="item.fileTypeDownload" @click="onDownloadDocumentClicked(item)">
              Download {{ item.fileTypeDownload }}
            </b-dropdown-item>

            <b-dropdown-divider
              v-if="item.fileTypePreview && item.fileTypeDownload && (item.actions.canCancelCheckout || item.actions.canCheckOut || item.actions.canResubmitCheckout)"
            />

            <b-dropdown-item
              v-if="item.actions.canCancelCheckout"
              @click="onCancelCheckoutClicked(item)"
            >
              Cancel check out
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.actions.canCheckOut"
              @click="onCheckoutClicked(item)"
            >
              Check out
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.actions.canResubmitCheckout"
              @click="onResubmitCheckoutClicked(item)"
            >
              Resend e-mail
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </template>
    </b-table>

    <checkout-confirmation-dialog :title="`Check out document ${(actionItem !== null ? actionItem.id : null)}`" :show="showCheckoutConfirmation" @ok="onCheckoutConfirmationOkClicked" @cancel="onConfirmationCancelled" @close="onConfirmationCancelled" />
    <cancel-checkout-confirmation-dialog :title="`Cancel document ${(actionItem !== null ? actionItem.id : null)} checkout`" :show="showCancelCheckoutConfirmation" @ok="onCancelCheckoutConfirmationOkClicked" @cancel="onConfirmationCancelled" @close="onConfirmationCancelled" />
    <resend-checkout-email-confirmation-dialog :title="`Resend checkout email for document ${(actionItem !== null ? actionItem.id : null)}`" :show="showResubmitCheckoutConfirmation" @ok="onResubmitCheckoutConfirmationOkClicked" @cancel="onConfirmationCancelled" @close="onConfirmationCancelled" />
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { ScrollMixin } from '@/mixins/scroll-mixin';
import { namespace } from 'vuex-class';
import { Tenant, ReportGroup } from '../../store/tenant/state';
import { DocumentLibrary, DocumentLibraryEntity, DocumentLibraryRegion, DocumentLibraryDate, DocumentLibraryLanguage, DocumentLibraryDocumentType, DocumentLibraryCommentaryType, DocumentLibraryInsertType, Document, DocumentsPage, DocumentLibraryFilters } from '../../store/document-library/state';
import { DateTime } from 'luxon';
import LockedStatusIcon from './document-status-icons/locked.vue';
import CheckingOutStatusIcon from './document-status-icons/checking-out.vue';
import CheckingInStatusIcon from './document-status-icons/checking-in.vue';
import { BTable } from 'bootstrap-vue';
import { Route } from 'vue-router';
import CheckoutConfirmationDialog from './checkout-confirmation-dialog.vue';
import CancelCheckoutConfirmationDialog from './cancel-checkout-confirmation-dialog.vue';
import ResendCheckoutEmailConfirmationDialog from './resend-checkout-email-confirmation-dialog.vue';
import TableFilterHeader from '@/components/table/table-header-filter.vue';
import ViewPdfIcon from '@/components/icons/view-pdf-icon.vue';
import DownloadPdfIcon from '@/components/icons/download-pdf-icon.vue';
import ViewExcelIcon from '@/components/icons/view-excel-icon.vue';
import ViewWordIcon from '@/components/icons/view-word-icon.vue';
import ViewPowerpointIcon from '@/components/icons/view-powerpoint-icon.vue';
import DownloadExcelIcon from '@/components/icons/download-excel-icon.vue';
import DownloadPowerpointIcon from '@/components/icons/download-powerpoint-icon.vue';
import DownloadWordIcon from '@/components/icons/download-word-icon.vue';
import * as Sentry from '@sentry/browser';
import { parseFilters, applyFilters } from '@/components/table/input-filter';
import DocumentFlagIcon from './document-flag-icon.vue';
import { FileUtils } from '@/utilities/file.utils';
import debounce from 'lodash.debounce';

const tenantModule = namespace('tenant');
const documentLibraryModule = namespace('documentLibrary');
const environmentModule = namespace('environment');
const DEFAULT_PAGE_SIZE = 250;

@Component({
  components: {
    LockedStatusIcon,
    CheckingOutStatusIcon,
    CheckingInStatusIcon,
    CheckoutConfirmationDialog,
    CancelCheckoutConfirmationDialog,
    ResendCheckoutEmailConfirmationDialog,
    TableFilterHeader,
    ViewPdfIcon,
    DownloadPdfIcon,
    ViewExcelIcon,
    ViewWordIcon,
    ViewPowerpointIcon,
    DownloadExcelIcon,
    DownloadPowerpointIcon,
    DownloadWordIcon,
    DocumentFlagIcon,
  },
})
export default class DocumentLibraryDocumentTable extends Mixins(BvToastMixin, ScrollMixin) {
  @tenantModule.Getter('current') currentTenant!: Tenant;
  @documentLibraryModule.Getter('currentReportGroup') selectedReportGroup!: ReportGroup;
  @documentLibraryModule.Getter('current') currentDocumentLibrary!: DocumentLibrary;
  @documentLibraryModule.Getter currentValuationDate!: DocumentLibraryDate | null;
  @documentLibraryModule.Getter currentValuationDateFrom!: DocumentLibraryDate | null;
  @documentLibraryModule.Getter currentValuationDateTo!: DocumentLibraryDate | null;
  @documentLibraryModule.Getter currentRegion!: DocumentLibraryRegion | null;
  @documentLibraryModule.Getter currentEntity!: DocumentLibraryEntity | null;
  @documentLibraryModule.Getter currentLanguage!: DocumentLibraryLanguage | null;
  @documentLibraryModule.Getter currentDocumentType!: DocumentLibraryDocumentType | null;
  @documentLibraryModule.Getter currentCommentaryType!: DocumentLibraryCommentaryType | null;
  @documentLibraryModule.Getter currentInsertType!: DocumentLibraryInsertType | null;
  @documentLibraryModule.Getter documents!: Array<Document>;
  @documentLibraryModule.Getter currentDocument!: Document | null;
  @documentLibraryModule.Getter filters!: DocumentLibraryFilters;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;

  loading = false;
  sortBy: string | null = null;
  sortDesc = false;
  selectedRowIndex = -1;
  preventScrollLoading = false;
  showCheckoutConfirmation = false;
  showCancelCheckoutConfirmation = false;
  showResubmitCheckoutConfirmation = false;
  actionItem: Document | null = null;
  showFilter = false;

  get fields(): Array<{}> {
    const fields = [
      { key: 'id', label: 'ID', class: ['col-1'], sortable: true },
      { key: 'name', label: 'Description', class: ['w-50'], sortable: true },
      { key: 'statusDescription', label: '', class: ['col-auto'], sortable: false },
      { key: 'status', label: '', class: ['col-auto'], sortable: false },
      { key: 'valuationDate', label: 'Date', class: ['col-auto'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); }, sortByFormatted: true },
      { key: 'flagged', label: '', class: ['col-auto'], sortable: true },
      { key: 'actions', label: '', tdClass: ['text-right'], class: ['col-1'] }
    ];

    if (this.currentDocumentLibrary.reportGroupId > 0) {
      const index = fields.findIndex((f) => f.key === 'statusDescription');

      if (index > -1) {
        fields.splice(index, 1);
      }
    }

    if (this.currentDocumentLibrary.searchType === 'DataFiles' || this.currentDocumentLibrary.searchType === 'StaticDocuments') {
      const index = fields.findIndex((f) => f.key === 'valuationDate');

      if (index > -1) {
        fields.splice(index, 1);
      }
    }

    if (this.currentDocumentLibrary.searchType !== 'DataFiles') {
      fields.splice(3, 0, { key: 'numberOfPages', label: 'Pages', class: ['col-auto'], sortable: true });
    }

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 196px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 196px)';
    }

    return 'calc(100vh - 220px)';
  }

  public get showFilterCount(): boolean {
    return this.filters.description !== null;
  }

  public get filteredDocuments(): Array<Document> {
    let results = this.documents;

    if (this.filters.description !== null) {
      const filters = parseFilters(this.filters.description);
      results = results.filter((doc) => applyFilters(doc, d => d.name, filters, { mode: 'include' }));
    }

    return results;
  }

  /* Scroll Mixin overrides */
  getScrollElement(): HTMLElement {
    return this.$el as HTMLDivElement;
  }

  onScrollCompleted(): void {
    this.$emit('document-table-scroll-completed');
  }

  mounted(): void {
    if (this.filters.description) {
      this.showFilter = true;
    }
  }

  public updated(): void {
    if (this.documents.length === 1) {
      (this.$refs.table as BTable).selectRow(0);
      this.$store.commit('documentLibrary/setSelectedDocumentId', this.documents[0].id);
    } else if (this.currentDocument !== null) {
      const index = ((this.$refs.table as BTable).computedItems as Array<Document>).findIndex((r) => r.id === this.currentDocument!.id);

      if (index !== this.selectedRowIndex) {
        this.selectedRowIndex = index;
      }

      (this.$refs.table as BTable).selectRow(this.selectedRowIndex);

      if (this.selectedRowIndex < 0) {
        this.$store.commit('documentLibrary/setSelectedDocumentId', null);
      }

      this.scrollToSelectedDocument();
    } else {
      if (this.$route.params.documentId !== undefined && this.$route.params.documentId !== null) {
        const index = ((this.$refs.table as BTable).computedItems as Array<Document>).findIndex((r) => r.id === this.$route.params.documentId);

        if (index !== this.selectedRowIndex) {
          this.selectedRowIndex = index;
        }

        (this.$refs.table as BTable).selectRow(this.selectedRowIndex);
        this.$store.commit('documentLibrary/setSelectedDocumentId', this.$route.params.documentId);
        this.scrollToSelectedDocument();
      } else {
        this.$store.commit('documentLibrary/setSelectedDocumentId', null);
      }
    }
  }

  applyTableRowClasses(item: Document, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
    if (item !== null && type !== 'table-busy') {
      return { replaced: item.replaced };
    }
  }

  canShowActions(item: Document): boolean {
    return (Object.keys(item.actions).length > 0 && Object.values(item.actions).some((a) => a)) || item.fileTypePreview !== null || item.fileTypeDownload !== null;
  }

  getViewFileTooltip(item: Document) {
    if (item.fileTypePreview && item.fileName) {
      return `View ${item.fileTypePreview} ${this.getFileNameWithoutExtension(item.fileName)}`;
    }

    return 'View';
  }

  getDownloadFileTooltip(item: Document): string {
    if (item.fileTypeDownload && item.fileName) {
      return `Download ${item.fileTypeDownload} ${this.getFileNameWithoutExtension(item.fileName)}`;
    }

    return 'Download';
  }

  getFileTypePrievew(item: Document): string {
    return item.fileTypePreview?.toLowerCase() ?? '';
  }

  getFileTypeDownload(item: Document): string {
    return item.fileTypeDownload?.toLowerCase() ?? '';
  }

  getFileNameWithoutExtension(fileName: string): string {
    const index = fileName.lastIndexOf('.');
    if (index > -1) {
      fileName = fileName.substring(0, index);
    }

    return fileName;
  }

  canPerformSearch(): boolean {
    if (this.currentDocumentLibrary === null) {
      return false;
    }

    switch (this.currentDocumentLibrary.searchType) {
      case 'DataFiles':
        return true;
      case 'StaticDocuments':
        return true;
      case 'RegionReports':
        return this.currentEntity !== null;
      case 'LanguageReports':
        return this.currentEntity !== null;
      case 'Commentary':
        return this.currentEntity !== null;
      case 'ManualInserts':
        return this.currentEntity !== null;
    }
  }

  @Watch('currentTenant')
  @Watch('currentDocumentLibrary')
  @Watch('currentValuationDate')
  @Watch('currentValuationDateFrom')
  @Watch('currentValuationDateTo')
  @Watch('currentRegion')
  @Watch('currentEntity')
  @Watch('currentLanguage')
  @Watch('currentDocumentType')
  @Watch('currentCommentaryType')
  @Watch('currentInsertType')
  async onFilterChanged(): Promise<void> {
    if (!this.canPerformSearch()) {
      this.$store.commit('documentLibrary/clearPages');
      return;
    }

    await this.performSearchAsync(1, DEFAULT_PAGE_SIZE);
  }

  @Watch('currentDocumentLibrary', { immediate: true })
  async onCurrentDocumentLibraryChangedForSearch(to: DocumentLibrary | null, from?: DocumentLibrary | null): Promise<void> {
    // NOTE(Dan): We need this additional check to prevent 2 searches firing when navigating to the page, with a document id in the URL.
    //            For example, navigating from the data files screen by clicking the document library link.
    if (from !== undefined) {
      return;
    }

    const { documentId } = this.$route.params;

    if (documentId !== undefined) {
      return;
    }

    if (!this.canPerformSearch()) {
      this.$store.commit('documentLibrary/clearPages');
      return;
    }

    await this.performSearchAsync(1, DEFAULT_PAGE_SIZE);
  }

  @Watch('currentDocumentLibrary')
  onCurrentDocumentLibraryChanged(to: DocumentLibrary, from: DocumentLibrary | null): void {
    this.sortBy = null;
    this.sortDesc = false;
  }

  @Watch('sortDesc')
  onSortDescChanged(to: boolean, from: boolean): void {
    if (!to && from) {
      this.sortBy = null;
    }
  }

  @Watch('currentDocument')
  async onSelectedDocumentChanged(document: Document | null, old: Document | null): Promise<void> {
    const { documentId, ...params } = this.$route.params;

    if (document !== null && document.id !== documentId) {
      await this.$router.push({ name: 'document-library', params: { ...params, documentId: document.id } });
      return;
    }

    if (document !== null && documentId === undefined) {
      await this.$router.push({ name: 'document-library', params: { ...params, documentId: document.id } });
    }
  }

  @Watch('$route')
  async onRouteChanged(to: Route, from: Route): Promise<void> {
    const { documentId } = to.params;

    if (documentId === undefined) {
      if (this.currentDocument !== null) {
        this.$emit('document-row-deselected');
        this.$store.commit('documentLibrary/setSelectedDocumentId', null);
      }
    } else {
      if (this.currentDocument !== null && this.currentDocument.id === documentId) {
        this.scrollToSelectedDocument();
        return;
      }
      const index = ((this.$refs.table as BTable).computedItems as Array<Document>).findIndex((r) => r.id === documentId);
      const match = this.documents.find((d) => d.id === documentId);

      if (match !== undefined) {
        this.$store.commit('documentLibrary/setSelectedDocumentId', match.id);
        this.$emit('document-row-selected', match);
        this.selectedRowIndex = index;
        (this.$refs.table as BTable).selectRow(this.selectedRowIndex);
        this.scrollToSelectedDocument();
      }
    }
  }

  @Watch('documents')
  async onDocumentsChanged(documents: Array<Document>): Promise<void> {
    if (this.loading) {
      this.loading = false;
    }

    const { documentId, ...params } = this.$route.params;

    if (documentId === undefined) {
      return;
    }

    const match = this.documents.find((d) => d.id === documentId);

    if (match !== undefined) {
      return;
    }

    this.$store.commit('documentLibrary/setSelectedDocumentId', null);

    await this.$router.replace({ name: this.$route.name!, params: { ...params } });
  }

  onRowClicked(item: Document, index: number): void {
    if (this.selectedRowIndex === index && this.currentDocument !== null) {
      this.selectedRowIndex = -1;
      this.$emit('document-row-deselected');
      const { documentId, ...params } = this.$route.params;
      this.$router.push({ name: 'document-library', params: { ...params } });
      this.$store.commit('documentLibrary/setSelectedDocumentId', null);
      return;
    }
    this.$emit('document-row-selected', item);
    this.selectedRowIndex = index;
    this.$store.commit('documentLibrary/setSelectedDocumentId', item.id);
  }

  onRowSelected(items: Array<Document>): void {
    if (this.selectedRowIndex > -1 && this.currentDocument !== null) {
      return;
    }

    if (items.length < 1 || items[0] === undefined) {
      return;
    }

    const item = items[0];
    const index = this.documents.findIndex((d) => d.id === item.id);

    this.selectedRowIndex = index;
    this.$store.commit('documentLibrary/setSelectedDocumentId', item.id);
  }

  onSortChanged(context: any): void {
    if (this.currentDocument === null) {
      return;
    }

    const table = (this.$refs.table as any);
    const computedItems = table.computedItems as Array<Document>;
    const newIndex = computedItems.findIndex((r) => r.id === this.currentDocument!.id);
    this.selectedRowIndex = newIndex;
    (this.$refs.table as BTable).selectRow(newIndex);
  }

  async performSearchAsync(page: number, pageSize: number): Promise<void> {
    this.loading = true;

    try {
      switch (this.currentDocumentLibrary.searchType) {
        case 'DataFiles':
          await this.$store.dispatch('documentLibrary/getDataFileDocumentsAsync', { reportGroupId: this.selectedReportGroup!.id, languageId: this.currentLanguage?.id || null, regionId: this.currentRegion?.id || null, page: page, pageSize: pageSize });
          break;
        case 'StaticDocuments':
          await this.$store.dispatch('documentLibrary/getStaticDocumentsAsync', { reportGroupId: this.selectedReportGroup!.id, documentType: this.currentDocumentType?.name || null, languageId: this.currentLanguage?.id || null, regionId: this.currentRegion?.id || null, page: page, pageSize: pageSize });
          break;
        case 'RegionReports':
          await this.$store.dispatch('documentLibrary/getRegionReportDocumentsAsync', { reportGroupId: this.selectedReportGroup!.id, entityId: this.currentEntity?.id || null, regionId: this.currentRegion?.id || null, valuationDateFrom: this.currentValuationDateFrom?.valuationDate || null, valuationDateTo: this.currentValuationDateTo?.valuationDate || null, page: page, pageSize: pageSize });
          break;
        case 'LanguageReports':
          await this.$store.dispatch('documentLibrary/getLanguageReportDocumentsAsync', { reportGroupId: this.selectedReportGroup!.id, entityId: this.currentEntity?.id || null, languageId: this.currentLanguage?.id || null, valuationDateFrom: this.currentValuationDateFrom?.valuationDate || null, valuationDateTo: this.currentValuationDateTo?.valuationDate || null, page: page, pageSize: pageSize });
          break;
        case 'Commentary':
          await this.$store.dispatch('documentLibrary/getCommentaryDocumentsAsync', { reportGroupId: this.selectedReportGroup!.id, entityId: this.currentEntity?.id || null, commentaryType: this.currentCommentaryType?.name || null, languageId: this.currentLanguage?.id || null, valuationDate: this.currentValuationDate?.valuationDate || null, page: page, pageSize: pageSize });
          break;
        case 'ManualInserts':
          await this.$store.dispatch('documentLibrary/getManualInsertDocumentsAsync', { reportGroupId: this.selectedReportGroup!.id, entityId: this.currentEntity?.id || null, insertType: this.currentInsertType?.name || null, languageId: this.currentLanguage?.id || null, valuationDate: this.currentValuationDate?.valuationDate || null, page: page, pageSize: pageSize });
          break;
      }
    } catch (e) {
      // TODO(Dan): Error handling here...
      Sentry.captureException(e);
    }

    this.loading = false;
  }

  async onDownloadDocumentClicked(item: Document): Promise<void> {
    try {
      const data = await this.$store.dispatch('documentLibrary/downloadDocumentAsync', { documentId: item.id, reportGroupId: this.selectedReportGroup.id, isPreview: false }) as Blob;
      FileUtils.downloadFile(data, item.fileName!);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
      Sentry.captureException(e);
    }
  }

  async onViewDocumentClicked(item: Document): Promise<void> {
    try {
      const url = await this.$store.dispatch('documentLibrary/generateDocumentReportPreviewUrlAsync', { documentId: item.id, reportGroupId: this.selectedReportGroup.id, isPreview: true }) as string;

      window.open(url, '_blank');
    } catch (e) {
      this.showErrorToast('Could not view file. Please try again.');
      Sentry.captureException(e);
    }
  }

  onCheckoutClicked(item: Document): void {
    this.actionItem = item;
    this.showCheckoutConfirmation = true;
    this.showCancelCheckoutConfirmation = false;
  }

  onCancelCheckoutClicked(item: Document): void {
    this.actionItem = item;
    this.showCheckoutConfirmation = false;
    this.showCancelCheckoutConfirmation = true;
  }

  onResubmitCheckoutClicked(item: Document): void {
    this.actionItem = item;
    this.showCheckoutConfirmation = false;
    this.showCancelCheckoutConfirmation = false;
    this.showResubmitCheckoutConfirmation = true;
  }

  async onCheckoutConfirmationOkClicked(): Promise<void> {
    try {
      await this.$store.dispatch('documentLibrary/checkoutDocumentAsync', { documentId: this.actionItem!.id, reportGroupId: this.selectedReportGroup!.id });
    } catch (e) {
      this.showErrorToast(`Could not checkout document '${this.actionItem!.id}'. Please try again.`);
      Sentry.captureException(e);
    }

    this.onConfirmationCancelled();
  }

  async onCancelCheckoutConfirmationOkClicked(): Promise<void> {
    try {
      await this.$store.dispatch('documentLibrary/cancelDocumentCheckoutAsync', { documentId: this.actionItem!.id, reportGroupId: this.selectedReportGroup!.id });
    } catch (e) {
      this.showErrorToast(`Could not cancel checkout for document '${this.actionItem!.id}'. Please try again.`);
      Sentry.captureException(e);
    }

    this.onConfirmationCancelled();
  }

  async onResubmitCheckoutConfirmationOkClicked(): Promise<void> {
    try {
      await this.$store.dispatch('documentLibrary/checkoutDocumentAsync', { documentId: this.actionItem!.id, reportGroupId: this.selectedReportGroup!.id });
    } catch (e) {
      this.showErrorToast(`Could not re-send checkout e-mail for document '${this.actionItem!.id}'. Please try again.`);
      Sentry.captureException(e);
    }

    this.onConfirmationCancelled();
  }

  onConfirmationCancelled(): void {
    this.actionItem = null;
    this.showCheckoutConfirmation = false;
    this.showCancelCheckoutConfirmation = false;
    this.showResubmitCheckoutConfirmation = false;
  }

  onDescriptionFilterClear(): void {
    this.$store.commit('documentLibrary/clearFilters');
  }

  onDescriptionFiltered = debounce(this.onDescriptionFilteredInternal, 250);
  onDescriptionFilteredInternal(value: string | null) {
    this.$store.commit('documentLibrary/setFilters', { ...this.filters, description: value });
  }

  onShowFilter(id: string): void {
    this.showFilter = !this.showFilter;

    if (this.showFilter) {
      this.$nextTick(() => (this.$refs[`${id}-filter`] as TableFilterHeader).focus());
    }
  }

  scrollToSelectedDocument(): void {
    this.$nextTick(() => {
      const div = this.$el.querySelector('.documents-table') as HTMLDivElement;
      const table = this.$refs.table as BTable;
      const header = table.$el.querySelector('thead') as HTMLTableSectionElement;
      const row = table.$el.querySelector('.b-table-row-selected') as HTMLTableRowElement | null;

      if (row === null) {
        return;
      }

      const containerHeight = table.$el.clientHeight;
      const rowTop = row.getBoundingClientRect().top - div.getBoundingClientRect().top;
      const rowBottom = rowTop + row.offsetHeight;
      const total = rowTop >= 0 && rowBottom <= containerHeight;
      const partial = (rowTop < 0 && rowBottom > 0) || (rowTop > 0 && rowTop <= containerHeight);

      if (total || partial) {
        return;
      }

      div.scrollTop = div.scrollTop + rowTop - header.offsetHeight;
    });
  }
}
</script>
