import { Api } from '@/api/api';
import { DocumentLibraryResponse } from '@/api/responses/document-library/document-library-response';
import { DocumentsPageResponse } from './responses/document-library/documents-page-response';
import { DocumentActivityLogResponse } from './responses/document-library/document-activity-log-response';
import { DocumentDistributionsResponse } from './responses/document-library/document-distributions-response';
import { FindDocumentResponse } from './responses/document-library/find-document-response';
import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash.debounce';

export class DocumentLibraryApi extends Api {
  private pageCancellationToken: CancelTokenSource | null = null;

  public constructor() {
    super();
  }

  public getDocumentLibraryForReportGroupAsync(reportGroupId: number): Promise<DocumentLibraryResponse> {
    return this.getAsync<DocumentLibraryResponse>(`v1/documentlibrary/${reportGroupId}`);
  }

  public getDataFileDocumentsAsync(reportGroupId: number, params: { languageId: number | null, regionId: number | null, page: number | null, pageSize: number | null }): Promise<DocumentsPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DocumentsPageResponse>(`v1/documentlibrary/${reportGroupId}/datafile`, params, this.pageCancellationToken!.token);
  }

  public getStaticDocumentsAsync(reportGroupId: number, params: { documentType: string, languageId: number | null, regionId: number | null, page: number | null, pageSize: number | null }): Promise<DocumentsPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DocumentsPageResponse>(`v1/documentlibrary/${reportGroupId}/static`, params, this.pageCancellationToken!.token);
  }

  public getRegionReportDocumentsAsync(reportGroupId: number, params: { entityId: number, regionId: number | null, valuationDateFrom: string | null, valuationDateTo: string | null, page: number | null, pageSize: number | null }): Promise<DocumentsPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DocumentsPageResponse>(`v1/documentlibrary/${reportGroupId}/regionreport`, params, this.pageCancellationToken!.token);
  }

  public getLanguageReportDocumentsAsync(reportGroupId: number, params: { entityId: number, languageId: number | null, valuationDateFrom: string | null, valuationDateTo: string | null, page: number | null, pageSize: number | null }): Promise<DocumentsPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }

    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DocumentsPageResponse>(`v1/documentlibrary/${reportGroupId}/languagereport`, params, this.pageCancellationToken!.token);
  }

  public async getCommentaryDocumentsAsync(reportGroupId: number, params: { entityId: number, commentaryType: string | null, valuationDate: string | null, languageId: number | null, page: number | null, pageSize: number | null }): Promise<DocumentsPageResponse> {
    if (this.pageCancellationToken) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }
    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();
    return new Promise<DocumentsPageResponse>((resolve, reject) => {
      const debouncedFunction = debounce(async () => {
        try {
          const response = await this.getAsync<DocumentsPageResponse>(`v1/documentlibrary/${reportGroupId}/commentary`, params, this.pageCancellationToken!.token);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }, 200);

      debouncedFunction();
    });
  }

  public getManualInsertDocumentsAsync(reportGroupId: number, params: { entityId: number, insertType: string | null, languageId: number | null, valuationDate: string | null, page: number | null, pageSize: number | null }): Promise<DocumentsPageResponse> {
    if (this.pageCancellationToken !== null) {
      this.pageCancellationToken.cancel('Request cancelled. Another request has been started.');
    }
    this.pageCancellationToken = axios.CancelToken.source();
    this.pageCancellationToken.token.throwIfRequested();

    return this.getAsync<DocumentsPageResponse>(`v1/documentlibrary/${reportGroupId}/manualinsert`, params, this.pageCancellationToken!.token);
  }

  public getDocumentActivityAsync(documentId: string, reportGroupId: number): Promise<DocumentActivityLogResponse> {
    return this.getAsync<DocumentActivityLogResponse>(`v1/documentlibrary/${reportGroupId}/document/${documentId}/activity`);
  }

  public getDocumentDistributionAsync(documentId: string, reportGroupId: number): Promise<DocumentDistributionsResponse> {
    return this.getAsync<DocumentDistributionsResponse>(`v1/documentlibrary/${reportGroupId}/document/${documentId}/distribution`);
  }

  public downloadDocumentAsync(documentId: string, reportGroupId: number, isPreview: boolean): Promise<Blob> {
    return this.getFileAsync(`v1/documentlibrary/${reportGroupId}/document/${documentId}/download/${isPreview}`);
  }

  public generateDocumentReportPreviewUrlAsync(documentId: string, reportGroupId: number, isPreview: boolean): Promise<string> {
    return this.getAsync<string>(`v1/documentLibrary/${reportGroupId}/document/${documentId}/generate-preview-url/${isPreview}`);
  }

  public checkoutDocumentAsync(documentId: string, reportGroupId: number): Promise<void> {
    return this.postAsync(`v1/documentlibrary/${reportGroupId}/document/${documentId}/checkout`, null);
  }

  public cancelDocumentCheckoutAsync(documentId: string, reportGroupId: number): Promise<Blob> {
    return this.deleteAsync(`v1/documentlibrary/${reportGroupId}/document/${documentId}/checkout`);
  }

  public findDocumentAsync(documentId: string | number): Promise<FindDocumentResponse> {
    return this.getAsync<FindDocumentResponse>(`v1/documentlibrary/document/${documentId}`);
  }

  public getDocumentAsync(documentId: string, reportGroupId: number): Promise<FindDocumentResponse> {
    return this.getAsync<FindDocumentResponse>(`v1/documentlibrary/${reportGroupId}/document/${documentId}`);
  }

  public bulkDownloadDocumentsAsync(reportGroupId: number, files: { fileName: string, filePath: string }[]) : Promise<Blob> {
    return this.getFileAsPostAsync(`v1/documentlibrary/${reportGroupId}/document/bulk-download`, { files });
  }
}
