import { ReportGroup } from '../tenant/state';

export class State {
  current: DocumentLibrary | null = null;
  currentValuationDate: DocumentLibraryDate | null = null;
  currentValuationDateFrom: DocumentLibraryDate | null = null;
  currentValuationDateTo: DocumentLibraryDate | null = null;
  currentRegion: DocumentLibraryRegion | null = null;
  currentEntity: DocumentLibraryEntity | null = null;
  currentLanguage: DocumentLibraryLanguage | null = null;
  currentInsertType: DocumentLibraryInsertType | null = null;
  currentCommentaryType: DocumentLibraryCommentaryType | null = null;
  currentDocumentType: DocumentLibraryDocumentType | null = null;
  pages: Array<DocumentsPage> = [];
  selectedDocumentId: string | null = null;
  currentReportGroup: ReportGroup | null = null;
  downloadingDocuments = false;
  filters: DocumentLibraryFilters = { description: null };
}

export interface DocumentLibrary {
  reportGroupId: number;
  dateFormat: string;
  entityDescription: string | null;
  entities: Array<DocumentLibraryEntity>;
  dates: Array<DocumentLibraryDate>;
  regions: Array<DocumentLibraryRegion>;
  languages: Array<DocumentLibraryLanguage>;
  insertTypes: Array<DocumentLibraryInsertType>;
  commentaryTypes: Array<DocumentLibraryCommentaryType>;
  documentTypes: Array<DocumentLibraryDocumentType>;
  searchType: 'DataFiles' | 'StaticDocuments' | 'RegionReports' | 'LanguageReports' | 'Commentary' | 'ManualInserts';
}

export interface DocumentLibraryDate {
  valuationDate: string;
  displayDate: string;
}

export interface DocumentLibraryRegion {
  id: number;
  name: string;
  languageId: number;
}

export interface DocumentLibraryEntity {
  id: number;
  name: string;
}

export interface DocumentLibraryLanguage {
  id: number;
  name: string;
}

export interface DocumentLibraryInsertType {
  name: string;
}

export interface DocumentLibraryCommentaryType {
  name: string;
}

export interface DocumentLibraryDocumentType {
  name: string;
}

export interface DocumentsPage {
  page: number;
  pageSize: number;
  total: number;
  reportGroupId: number;
  documents: Array<Document>;
}

export interface Document {
  id: string;
  name: string;
  status: 'Available' | 'CheckingOut' | 'CheckedOut' | 'CheckingIn' | 'Locked';
  statusDescription: string | null;
  numberOfPages: number;
  fileName: string | null;
  filePath: string | null;
  fileType: string | null;
  fileTypeDownload: string | null;
  fileTypePreview: string | null;
  replaced: boolean;
  valuationDate: string | null;
  displayDate: string | null;
  dateFormat: string | null;
  hasDistribution: boolean;
  flagged: boolean;
  flaggedReason: string | null;
  actions: { [key in keyof Actions]: boolean }
}

interface Actions {
  canCheckOut: boolean;
  canCancelCheckout: boolean;
  canResubmitCheckout: boolean;
}

export interface DocumentLibraryFilters {
  description: string | null;
}
