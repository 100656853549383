import { GetterTree } from 'vuex';
import { State } from '@/store/support/state';

export class Getters implements GetterTree<State, any> {
  [key: string]: (state: State, getters: any, rootState: any, rootGetters: any) => any;

  public current = (state: State) => {
    return state.current;
  }

  public categories = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.categories;
  }

  public issueTypes = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.issueTypes;
  }

  public priorities = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.priorities;
  }

  public dates = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.valuationDates;
  }

  public status = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.status;
  }

  public currentStatus = (state: State) => {
    if (state.current === null) {
      return null;
    }

    return this.status(state)[state.status] || null;
  }

  public agents = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.agents;
  }

  public users = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.current.users;
  }

  public usersForTenant = (state: State) => (tenantId: number) => {
    return this.users(state).filter((u) => u.tenantId === tenantId);
  }

  public tickets = (state: State) => {
    if (state.current === null) {
      return [];
    }

    return state.pages.flatMap((p) => p.tickets);
  }

  public pages = (state: State) => {
    return state.pages;
  }

  public ticket = (state: State) => {
    return state.ticket;
  }

  public attachments = (state: State) => {
    return state.attachments;
  }

  public messages = (state: State) => {
    return state.messages?.messages || [];
  }

  public notes = (state: State) => {
    return state.notes?.messages || [];
  }

  public activity = (state: State) => {
    return state.activity?.activities || [];
  }

  public openingTicketCorrelationId = (state: State) => {
    return state.openingTicketCorrelationId;
  }

  public openingTicketId = (state: State) => {
    return state.openingTicketId;
  }

  public isLoading = (state: State) => {
    return state.loading;
  }

  public filters = (state: State) => {
    return state.filters;
  }
}
