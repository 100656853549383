<template>
  <div>
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1.25006C13.8563 1.25006 11.25 3.69666 11.25 6.78342C11.25 8.26189 11.853 9.60082 12.825 10.5878C12.8505 10.688 12.8198 11.0052 12.4936 11.4734C12.3267 11.6787 12.2803 11.9568 12.3716 12.2052C12.4628 12.4536 12.6783 12.6355 12.9384 12.6839C13.8291 12.8496 14.9352 12.603 15.7245 12.1832C16.0889 12.2517 16.6288 12.3172 17 12.3168C20.1437 12.3168 22.75 9.87018 22.75 6.78342C22.75 3.69666 20.1437 1.25006 17 1.25006ZM15.4922 6.25006C15.078 6.25006 14.7422 6.58585 14.7422 7.00006C14.7422 7.41427 15.078 7.75006 15.4922 7.75006H15.5003C15.9145 7.75006 16.2503 7.41427 16.2503 7.00006C16.2503 6.58585 15.9145 6.25006 15.5003 6.25006H15.4922ZM18.4922 6.25006C18.078 6.25006 17.7422 6.58585 17.7422 7.00006C17.7422 7.41427 18.078 7.75006 18.4922 7.75006H18.5003C18.9145 7.75006 19.2503 7.41427 19.2503 7.00006C19.2503 6.58585 18.9145 6.25006 18.5003 6.25006H18.4922Z" fill="#141B34" />
      <path d="M4.00027 12.2501C4.00027 10.3171 5.56727 8.75006 7.50027 8.75006C9.43326 8.75006 11.0003 10.3171 11.0003 12.2501C11.0003 14.1831 9.43326 15.7501 7.50027 15.7501C5.56727 15.7501 4.00027 14.1831 4.00027 12.2501Z" fill="#141B34" />
      <path d="M3.8261 17.6756C6.07808 16.4414 8.92246 16.4414 11.1744 17.6756C11.6058 17.8992 12.5496 18.39 13.0334 18.8259C13.3317 19.0946 13.676 19.4993 13.74 20.0385C13.8108 20.6347 13.5177 21.1474 13.0592 21.5494C12.311 22.2056 11.3903 22.7499 10.1947 22.7499H4.8058C3.61027 22.7499 2.68958 22.2056 1.94129 21.5494C1.48287 21.1474 1.18972 20.6347 1.26052 20.0385C1.32455 19.4993 1.66888 19.0946 1.96718 18.8259C2.45098 18.39 3.39471 17.8992 3.8261 17.6756Z" fill="#141B34" />
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class QuestionSolidRounded extends Vue {
}
</script>
