import throttle from 'lodash/throttle';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class ScrollMixin extends Vue {
  lastScrollPos = 0;

  mounted() {
    const element = this.getScrollElement();
    if (element) {
      element.addEventListener('scroll', this.onScroll);
    }
  }

  beforeDestroy() {
    const element = this.getScrollElement();
    if (element) {
      element.removeEventListener('scroll', this.onScroll);
    }
  }

  getScrollElement(): HTMLElement {
    throw new Error("Component must implement 'getScrollElement' method to specify the scrollable element.");
  }

  onScrollCompleted(): void {
    throw new Error("Component must implement 'onScrollCompleted' method to specify the function to be called when the scroll is completed.");
  }

  onScroll = throttle(this.onScrollInternal, 1000);
  onScrollInternal() {
    const element = this.getScrollElement();
    if (!element) return;

    const offsetHeight = element.offsetHeight;
    const scrolled = element.scrollTop;
    const height = element.scrollHeight;

    // We're scrolling back up the list from the bottom, so we don't want to trigger here
    if (this.lastScrollPos >= scrolled) {
      this.lastScrollPos = scrolled;
      return;
    }

    this.lastScrollPos = scrolled;

    if (offsetHeight + scrolled >= height * 0.9) {
      this.onScrollCompleted();
    }
  }
};
