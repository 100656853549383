<template>
  <validation-observer v-slot="{ passes, invalid, pending }">
    <b-modal
      id="modal-center"
      v-model="visible"
      ok-variant="primary"
      ok-title="Rename"
      cancel-variant="link"
      centered
      :title="modalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      size="md"
      :ok-disabled="(invalid && !pending) || submitting"
      @ok.prevent="passes(onOk)"
      @cancel="onCancel"
      @close="onClose"
    >
      <validation-provider rules="required|max:100" v-slot="{ valid, errors }" tag="div">
        <b-form-group label="Name">
          <b-form-input
            id="team-name"
            v-model="name"
            autofocus
            :formatter="format"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-modal>
  </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { removeNonAlphanumeric } from '@/utilities/text.utils';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RenameTeamDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ type: String, required: true }) public team!: string;

  private name: string | null = this.team;
  private visible: boolean = false;
  private submitting: boolean = false;

  get modalTitle(): string {
    return `Rename team '${this.team}'`;
  }

  onOk(): void {
    this.submitting = true;
    this.$emit('ok', {
      name: this.name,
    });
  }

  onCancel(): void {
    this.$emit('cancel');
    this.clear();
  }

  onClose(): void {
    this.$emit('close');
    this.clear();
  }

  @Watch('show', { immediate: true })
  onShowChanged(value: boolean): void {
    this.visible = value;
    this.clear();
  }

  clear(): void {
    this.name = null;
  }

  format(value: string): string {
    return removeNonAlphanumeric(value);
  }
}
</script>
