import { ActionTree, ActionContext } from 'vuex';
import { State, Document } from '@/store/document-library/state';
import { DocumentLibraryApi } from '@/api/document-library-api';
import { FindDocumentResponse } from '@/api/responses/document-library/find-document-response';
import { DocumentCheckInCompleted } from '@/services/notifications/events/document-checkin-completed';
import { DocumentCheckOutCompleted } from '@/services/notifications/events/document-checkout-completed';
import { DocumentCheckOutCancelled } from '@/services/notifications/events/document-checkout-cancelled';
import * as Sentry from '@sentry/browser';
import { Dictionary } from 'vue-router/types/router';

type DocumentLibraryState = { tenant: string, reportGroup: string, entity: string, languageOrRegion: string, documentId?: string };

const documentLibraryApi = new DocumentLibraryApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public getDocumentLibraryForReportGroupAsync = async (context: ActionContext<State, any>, reportGroup: { id: number; name: string }) => {
    try {
      const result = await documentLibraryApi.getDocumentLibraryForReportGroupAsync(reportGroup.id);

      if (context.state.current?.entityDescription !== result.entityDescription) {
        context.commit('setCurrentEntity', null);
      }

      context.commit('setCurrent', result);

      if (result.searchType === 'DataFiles') {
        context.commit('setCurrentValuationDate', null);
        context.commit('setCurrentValuationDateFrom', null);
        context.commit('setCurrentValuationDateTo', null);
        context.commit('setCurrentRegion', null);
        context.commit('setCurrentEntity', null);
        context.commit('setCurrentLanguage', null);
        context.commit('setCurrentInsertType', null);
        context.commit('setCurrentCommentaryType', null);
        context.commit('setCurrentDocumentType', null);
      }

      if (result.searchType === 'StaticDocuments') {
        context.commit('setCurrentValuationDate', null);
        context.commit('setCurrentValuationDateFrom', null);
        context.commit('setCurrentValuationDateTo', null);
        context.commit('setCurrentEntity', null);
        context.commit('setCurrentInsertType', null);
        context.commit('setCurrentCommentaryType', null);

        // TODO(Dan): Check document type / language / region to ensure it's still valid and reset to the one returned here.

        const documentType = result.documentTypes.find((d) => d.name === context.state.currentDocumentType?.name);
        const language = result.languages.find((l) => l.id === context.state.currentLanguage?.id);
        const region = result.regions.find((r) => r.id === context.state.currentRegion?.id);

        if (documentType === undefined) {
          context.commit('setCurrentDocumentType', null);
        } else {
          context.commit('setCurrentDocumentType', documentType);
        }

        if (language === undefined) {
          context.commit('setCurrentLanguage', null);
        } else {
          context.commit('setCurrentLanguage', language);
        }

        if (region === undefined) {
          context.commit('setCurrentRegion', null);
        } else {
          context.commit('setCurrentRegion', region);
        }
      }

      if (result.searchType === 'RegionReports') {
        context.commit('setCurrentValuationDate', null);
        context.commit('setCurrentLanguage', null);
        context.commit('setCurrentInsertType', null);
        context.commit('setCurrentCommentaryType', null);
        context.commit('setCurrentDocumentType', null);

        // TODO(Dan): Check entity / region / to and from to ensure it's still valid and reset to the one returned here.
        const entity = result.entities.find((e) => e.id === context.state.currentEntity?.id);
        const to = result.dates.find((d) => d.valuationDate === context.state.currentValuationDateTo?.valuationDate);
        const from = result.dates.find((d) => d.valuationDate === context.state.currentValuationDateFrom?.valuationDate);
        const region = result.regions.find((r) => r.id === context.state.currentRegion?.id);

        if (entity === undefined) {
          context.commit('setCurrentEntity', null);
        } else {
          context.commit('setCurrentEntity', entity);
        }

        if (region === undefined) {
          context.commit('setCurrentRegion', null);
        } else {
          context.commit('setCurrentRegion', region);
        }

        if (to === undefined) {
          context.commit('setCurrentValuationDateTo', null);
        } else {
          context.commit('setCurrentValuationDateTo', to);
        }

        if (from === undefined) {
          context.commit('setCurrentValuationDateFrom', null);
        } else {
          context.commit('setCurrentValuationDateFrom', from);
        }
      }

      if (result.searchType === 'LanguageReports') {
        context.commit('setCurrentValuationDate', null);
        context.commit('setCurrentRegion', null);
        context.commit('setCurrentLanguage', null);
        context.commit('setCurrentInsertType', null);
        context.commit('setCurrentCommentaryType', null);
        context.commit('setCurrentDocumentType', null);

        // TODO(Dan): Check entity / language / to and from to ensure it's still valid and reset to the one returned here
        const entity = result.entities.find((e) => e.id === context.state.currentEntity?.id);
        const to = result.dates.find((d) => d.valuationDate === context.state.currentValuationDateTo?.valuationDate);
        const from = result.dates.find((d) => d.valuationDate === context.state.currentValuationDateFrom?.valuationDate);
        const language = result.languages.find((l) => l.id === context.state.currentLanguage?.id);

        if (entity === undefined) {
          context.commit('setCurrentEntity', null);
        } else {
          context.commit('setCurrentEntity', entity);
        }

        if (language === undefined) {
          context.commit('setCurrentLanguage', null);
        } else {
          context.commit('setCurrentLanguage', language);
        }

        if (to === undefined) {
          context.commit('setCurrentValuationDateTo', null);
        } else {
          context.commit('setCurrentValuationDateTo', to);
        }

        if (from === undefined) {
          context.commit('setCurrentValuationDateFrom', null);
        } else {
          context.commit('setCurrentValuationDateFrom', from);
        }
      }

      if (result.searchType === 'Commentary') {
        context.commit('setCurrentValuationDateFrom', null);
        context.commit('setCurrentValuationDateTo', null);
        context.commit('setCurrentRegion', null);
        context.commit('setCurrentInsertType', null);
        context.commit('setCurrentDocumentType', null);

        // TODO(Dan): Check entity / language / date and commentaryType to ensure it's still valid and reset to the one returned here.
        const entity = result.entities.find((e) => e.id === context.state.currentEntity?.id);
        const date = result.dates.find((d) => d.valuationDate === context.state.currentValuationDate?.valuationDate);
        const commentaryType = result.commentaryTypes.find((c) => c.name === context.state.currentCommentaryType?.name);
        const language = result.languages.find((l) => l.id === context.state.currentLanguage?.id);

        if (entity === undefined) {
          context.commit('setCurrentEntity', null);
        }

        if (language === undefined) {
          context.commit('setCurrentLanguage', null);
        }

        if (date === undefined) {
          context.commit('setCurrentValuationDate', null);
        }

        if (commentaryType === undefined) {
          context.commit('setCurrentCommentaryType', null);
        }
      }

      if (result.searchType === 'ManualInserts') {
        context.commit('setCurrentValuationDate', null);
        context.commit('setCurrentValuationDateFrom', null);
        context.commit('setCurrentValuationDateTo', null);
        context.commit('setCurrentRegion', null);
        context.commit('setCurrentEntity', null);
        context.commit('setCurrentLanguage', null);
        context.commit('setCurrentInsertType', null);
        context.commit('setCurrentCommentaryType', null);
        context.commit('setCurrentDocumentType', null);

        // TODO(Dan): Check entity / language / date and insertType to ensure it's still valid and reset to the one returned here.
        const entity = result.entities.find((e) => e.id === context.state.currentEntity?.id);
        const date = result.dates.find((d) => d.valuationDate === context.state.currentValuationDate?.valuationDate);
        const insertType = result.insertTypes.find((i) => i.name === context.state.currentInsertType?.name);
        const language = result.languages.find((l) => l.id === context.state.currentLanguage?.id);

        if (entity === undefined) {
          context.commit('setCurrentEntity', null);
        } else {
          context.commit('setCurrentEntity', entity);
        }

        if (language === undefined) {
          context.commit('setCurrentLanguage', null);
        } else {
          context.commit('setCurrentLanguage', language);
        }

        if (date === undefined) {
          context.commit('setCurrentValuationDate', null);
        } else {
          context.commit('setCurrentValuationDate', date);
        }

        if (insertType === undefined) {
          context.commit('setCurrentInsertType', null);
        } else {
          context.commit('setCurrentInsertType', insertType);
        }
      }

      return result;
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  public getDataFileDocumentsAsync = async(context: ActionContext<State, any>, options: { reportGroupId: number, languageId: number | null, regionId: number | null, page: number | null, pageSize: number | null }) => {
    try {
      const result = await documentLibraryApi.getDataFileDocumentsAsync(options.reportGroupId, { languageId: options.languageId, regionId: options.regionId, page: options.page, pageSize: options.pageSize });

      context.commit('addPage', result);
    } catch (e) {
    }
  }

  public getStaticDocumentsAsync = async(context: ActionContext<State, any>, options: { reportGroupId: number, documentType: string, languageId: number | null, regionId: number | null, page: number | null, pageSize: number | null }) => {
    try {
      const result = await documentLibraryApi.getStaticDocumentsAsync(options.reportGroupId, { documentType: options.documentType, languageId: options.languageId, regionId: options.regionId, page: options.page, pageSize: options.pageSize });

      context.commit('addPage', result);
    } catch (e) {
    }
  }

  public getRegionReportDocumentsAsync = async(context: ActionContext<State, any>, options: { reportGroupId: number, entityId: number, regionId: number | null, valuationDateFrom: string | null, valuationDateTo: string | null, page: number | null, pageSize: number | null }) => {
    try {
      const result = await documentLibraryApi.getRegionReportDocumentsAsync(options.reportGroupId, { entityId: options.entityId, regionId: options.regionId, valuationDateFrom: options.valuationDateFrom, valuationDateTo: options.valuationDateTo, page: options.page, pageSize: options.pageSize });

      context.commit('addPage', result);
    } catch (e) {
    }
  }

  public getLanguageReportDocumentsAsync = async(context: ActionContext<State, any>, options: { reportGroupId: number, entityId: number, languageId: number | null, valuationDateFrom: string | null, valuationDateTo: string | null, page: number | null, pageSize: number | null }) => {
    try {
      const result = await documentLibraryApi.getLanguageReportDocumentsAsync(options.reportGroupId, { entityId: options.entityId, languageId: options.languageId, valuationDateFrom: options.valuationDateFrom, valuationDateTo: options.valuationDateTo, page: options.page, pageSize: options.pageSize });

      context.commit('addPage', result);
    } catch (e) {
    }
  }

  public getCommentaryDocumentsAsync = async(context: ActionContext<State, any>, options: { reportGroupId: number, entityId: number, commentaryType: string | null, languageId: number | null, valuationDate: string | null, page: number | null, pageSize: number | null }) => {
    try {
      const result = await documentLibraryApi.getCommentaryDocumentsAsync(options.reportGroupId, { entityId: options.entityId, commentaryType: options.commentaryType, languageId: options.languageId, valuationDate: options.valuationDate, page: options.page, pageSize: options.pageSize });

      context.commit('addPage', result);
    } catch (e) {
    }
  }

  public getManualInsertDocumentsAsync = async(context: ActionContext<State, any>, options: { reportGroupId: number, entityId: number, insertType: string | null, languageId: number | null, valuationDate: string | null, page: number | null, pageSize: number | null }) => {
    try {
      const result = await documentLibraryApi.getManualInsertDocumentsAsync(options.reportGroupId, { entityId: options.entityId, insertType: options.insertType, languageId: options.languageId, valuationDate: options.valuationDate, page: options.page, pageSize: options.pageSize });

      context.commit('addPage', result);
    } catch (e) {
    }
  }

  private getDocumentActivityAsync = async (context: ActionContext<State, any>, options: { documentId: string, reportGroupId: number }) => {
    try {
      const result = await documentLibraryApi.getDocumentActivityAsync(options.documentId, options.reportGroupId);

      return result;
    } catch (e) {
    }
  }

  private getDocumentDistributionAsync = async (context: ActionContext<State, any>, options: { documentId: string, reportGroupId: number }) => {
    try {
      const result = await documentLibraryApi.getDocumentDistributionAsync(options.documentId, options.reportGroupId);

      return result;
    } catch (e) {
    }
  }

  public downloadDocumentAsync = async (context: ActionContext<State, any>, options: { documentId: string, reportGroupId: number, isPreview: boolean }): Promise<Blob> => {
    return documentLibraryApi.downloadDocumentAsync(options.documentId, options.reportGroupId, options.isPreview);
  }

  public generateDocumentReportPreviewUrlAsync = async (context: ActionContext<State, any>, options: { documentId: string, reportGroupId: number, isPreview:boolean }): Promise<string> => {
    const url = await documentLibraryApi.generateDocumentReportPreviewUrlAsync(options.documentId, options.reportGroupId, options.isPreview);

    documentLibraryApi.refreshCorrelationId();

    return url;
  }

  public bulkDownloadDocumentsAsync = async (context: ActionContext<State, any>, options: { reportGroupId: number, files: { fileName: string, filePath: string }[] }): Promise<Blob> => {
    context.commit('setDownloading', true);

    try {
      return await documentLibraryApi.bulkDownloadDocumentsAsync(options.reportGroupId, options.files);
    } finally {
      context.commit('setDownloading', false);
    }
  }

  public checkoutDocumentAsync = (context: ActionContext<State, any>, options: { documentId: string, reportGroupId: number }) => {
    return documentLibraryApi.checkoutDocumentAsync(options.documentId, options.reportGroupId);
  }

  public cancelDocumentCheckoutAsync = (context: ActionContext<State, any>, options: { documentId: string, reportGroupId: number }) => {
    return documentLibraryApi.cancelDocumentCheckoutAsync(options.documentId, options.reportGroupId);
  }

  public findDocumentAsync = async (context: ActionContext<State, any>, documentId: string): Promise<FindDocumentResponse> => {
    const result = await documentLibraryApi.findDocumentAsync(documentId);

    return result;
  }

  public tryUpdateDocumentProperties = (context: ActionContext<State, any>, data: Partial<Document> & { documentId: string, reportGroupId: number }) => {
    if (data.reportGroupId !== context.state.current?.reportGroupId) {
      return;
    }

    const pages = context.state.pages;
    const page = pages.find((p) => p.documents.some((d) => d.id === data.documentId));

    if (page === undefined) {
      return;
    }

    const match = page.documents.find((d) => d.id === data.documentId);

    if (match === undefined) {
      return;
    }

    const { documentId, reportGroupId, ...source } = data;

    context.commit('updateDocumentProperties', { source: source, destination: match });
  }

  public tryReplaceDocumentOnPage = async (context: ActionContext<State, any>, data: (DocumentCheckInCompleted | DocumentCheckOutCompleted | DocumentCheckOutCancelled) & { documentId: string }) => {
    if (data.payload.reportGroupId !== context.state.current?.reportGroupId) {
      return;
    }

    const pages = context.state.pages;
    const page = pages.find((p) => p.documents.some((d) => d.id === data.documentId));

    if (page === undefined) {
      return;
    }

    const match = page.documents.find((d) => d.id === data.documentId);

    if (match === undefined) {
      return;
    }

    const document = await documentLibraryApi.getDocumentAsync(data.documentId, data.payload.reportGroupId);

    if (document === null) {
      return;
    }

    context.commit('updateDocumentProperties', { source: document, destination: match });
  }

  public applyStateFromRoute = async (context: ActionContext<State, any>, data: DocumentLibraryState): Promise<Dictionary<string>> => {
    /* eslint-disable */
    console.log('documentLibrary/applyStateFromRoute', data);
    /* eslint-enable */
    return {};
  }
}
