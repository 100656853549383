<template>
  <div class="data-issues" :aria-busy="loading">
    <b-table class="data-issues-table"
             ref="table"
             striped
             hover
             :sticky-header="headerStyle"
             show-empty
             sort-icon-left
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :busy="loading"
             :fields="fields"
             :items="filteredResults"
    >
      <template v-slot:head(name)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="reportFilter" @click.stop="onReportFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="reportFilter" />
      </template>

      <template v-slot:head(description)="data">
        <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="issueFilter" @click.stop="onIssueFilterClear">(clear filter)</b-button></span>
        <span @click.stop="onShowFilter">
          <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
            <g data-v-11c9e491="">
              <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
            </g>
          </svg>
        </span>
        <b-form-input class="mt-3" v-if="showFilter" v-model="issueFilter" />
      </template>

      <template v-slot:head(reportsAffected)="data">
        {{ data.label }} ({{ translationIssueReportsAffected }})
      </template>

      <template v-slot:cell(type)="{ item }">
        <data-issue-type-icon :item="item" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-btn variant="outline-primary" size="sm" @click="showFixTranslationIssueDialog(item)" v-if="item.links.languages !== null">Fix issue</b-btn>
      </template>
    </b-table>

    <fix-translation-issue-dialog v-if="showingFixTranslationIssueDialog" :show="showingFixTranslationIssueDialog" :issue="selectedTranslationIssue" @ok="onFixTranslationIssueDialogClosed" @close="onFixTranslationIssueDialogClosed" @cancel="onFixTranslationIssueDialogClosed" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import { ScrollMixin } from '@/mixins/scroll-mixin';
import { Location } from 'vue-router';
import { namespace } from 'vuex-class';
import { Tenant } from '../../store/tenant/state';
import throttle from 'lodash.throttle';
import { TranslationIssue, Data } from '../../store/data/state';
import FixTranslationIssueDialog from './fix-translation-issue-dialog.vue';
import { parseFilters, applyFilters } from '@/components/table/input-filter';
import DataIssueTypeIcon from './data-issue-type-icon.vue';

const tenantModule = namespace('tenant');
const dataModule = namespace('data');
const environmentModule = namespace('environment');

@Component({
  components: {
    FixTranslationIssueDialog,
    DataIssueTypeIcon,
  },
})
export default class TranslationIssuesTable extends Mixins(ScrollMixin) {
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @environmentModule.Getter('current') private environment!: { environment: string };
  @dataModule.Getter private translationIssueReportsAffected!: number;
  @dataModule.Getter private translationIssues!: Array<TranslationIssue>;
  @dataModule.Getter private current!: Data;

  private sortBy: string | null = null;
  private sortDesc: boolean = false;
  private selectedRowIndex: number = -1;
  private preventScrollLoading: boolean = false;
  private showFilter: boolean = false;
  private reportFilter: string | null = null;
  private issueFilter: string | null = null;
  private showingFixTranslationIssueDialog: boolean = false;
  private selectedTranslationIssue: TranslationIssue | null = null;

  public get filteredResults(): Array<TranslationIssue> {
    const translationIssues = this.translationIssues;

    if (translationIssues == null || translationIssues.length < 1) {
      return [];
    }

    if (!this.reportFilter && !this.issueFilter) {
      return translationIssues;
    }

    if (this.reportFilter && !this.issueFilter) {
      const reportFilters = parseFilters(this.reportFilter);
      return translationIssues.filter((r) => applyFilters(r, x => x.name, reportFilters, { mode: 'include' }));
    }

    if (!this.reportFilter && this.issueFilter) {
      const issueFilters = parseFilters(this.issueFilter);
      return translationIssues.filter((r) => applyFilters(r, x => x.description, issueFilters, { mode: 'include' }));
    }

    const reportFilters = parseFilters(this.reportFilter!);
    const issueFilters = parseFilters(this.issueFilter!);

    return translationIssues
      .filter((r) => applyFilters(r, x => x.name, reportFilters, { mode: 'include' }))
      .filter((r) => applyFilters(r, x => x.description, issueFilters, { mode: 'include' }));
  }

  private get fields(): Array<{}> {
    const fields = [
      // { key: 'id', label: 'ID', class: ['col-1'], sortable: true },
      { key: 'type', label: '', class: ['col-auto'], thClass: ['text-nowrap'], sortable: true },
      { key: 'name', label: 'Subject', class: ['col-auto'], sortable: true },
      { key: 'reportsAffected', label: 'Reports Affected', class: ['col-auto'], tdClass: ['text-center'], sortable: true },
      { key: 'description', label: 'Issue', class: ['w-100'], sortable: true },
      { key: 'actions', label: '', class: ['col-auto'], sortable: false }
    ];

    return fields;
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  /* Scroll Mixin overrides */
  getScrollElement(): HTMLElement {
    return (this.$refs.table as Vue).$el as HTMLDivElement;
  }

  onScrollCompleted(): void {
    this.$emit('scroll-completed');
  }

  private applyTableRowClasses(item: Document, type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy') {
  }

  private onShowFilter(): void {
    this.showFilter = !this.showFilter;
  }

  @Watch('sortDesc')
  private onSortDescChanged(to: boolean, from: boolean) {
    if (!to && from) {
      this.sortBy = null;
    }
  }

  private onReportFilterClear(): void {
    this.reportFilter = null;
    this.showFilter = false;
  }

  private onIssueFilterClear(): void {
    this.issueFilter = null;
    this.showFilter = false;
  }

  private buildRoute(item: TranslationIssue): Location | null {
    if (item.links.languages.length < 0) {
      return null;
    }

    const tenant = this.$route.params.tenant;
    const page = 'language-maintenance';
    const reportGroup = this.$route.params.reportGroup;
    const phrase = item.links.languages[0].phrase!;
    const language = item.links.languages[0].language!;

    return { name: page, params: { ...this.$route.params, tenant, reportGroup, phrase, language } };
  }

  private showFixTranslationIssueDialog(item: TranslationIssue): void {
    if (item.links.languages.length < 0) {
      return;
    }

    this.selectedTranslationIssue = item;
    this.showingFixTranslationIssueDialog = true;
  }

  private onFixTranslationIssueDialogClosed(): void {
    this.selectedTranslationIssue = null;
    this.showingFixTranslationIssueDialog = false;
  }

  private async onFixTranslationIssueDialogOk(): Promise<void> {
    try {
      await this.$store.dispatch('data/getDataForReportGroupAsync', this.current.reportGroupId);
    } finally {
      this.onFixTranslationIssueDialogClosed();
    }
  }
}
</script>
