import { ActionTree, ActionContext } from 'vuex';
import { State, Tenant } from '@/store/tenant/state';
import { TenantApi } from '@/api/tenant-api';
import userManager from '@/services/user-manager/user-manager';
import { UserApi } from '@/api/user-api';

const tenantApi = new TenantApi();
const userApi = new UserApi();

export class Actions implements ActionTree<State, any> {
  [key: string]: ((injectee: ActionContext<State, any>, payload: any) => any);

  public loadTenantsAsync = async (context: ActionContext<State, any>) => {
    try {
      const tenants = await tenantApi.getTenantsAsync();
      context.commit('setTenants', tenants);

      const currentUser = context.rootGetters['user/current'] as { email: string, name: string, currentTenantId: number } | null;

      if (currentUser !== null) {
        const currentTenantId = currentUser.currentTenantId;

        const match = tenants.find((t) => t.id === currentTenantId) || null;

        if (match !== null) {
          context.commit('setCurrentTenant', match);
          await context.dispatch('tenant/setCurrentTenantLogoAsync', currentTenantId, { root: true });
        }
      }
    } catch (e) {
      await userManager.removeUser();
    }
  }

  public setCurrentTenantLogoAsync = async (context: ActionContext<State, any>, currentTenantId: number) => {
    const logoBlob = await tenantApi.getTenantLogoAsync(currentTenantId);
    context.commit('setCurrentTenantLogo', URL.createObjectURL(logoBlob));
  }

  public setCurrentTenantAsync = async (context: ActionContext<State, any>, tenant: { id: number, name: string, logo: string }) => {
    try {
      const currentUser = context.rootGetters['user/current'] as { id: number, email: string, name: string, currentTenantId: number } | null;
      await userApi.updateActiveTenantAsync(currentUser!.id, tenant);

      if (currentUser != null) {
        currentUser.currentTenantId = tenant.id;
        await context.dispatch('tenant/setCurrentTenantLogoAsync', tenant.id, { root: true });
      }
    } catch (e) {
    }
  }

  public setCurrentReportGroupAsync = async (context: ActionContext<State, any>, payload: { tenantId: number, tenantName: string, id: number, name: string }) => {
    try {
      const currentUser = context.rootGetters['user/current'] as { id: number, email: string, name: string, currentTenantId: number } | null;
      await userApi.updateActiveReportGroupAsync(currentUser!.id, payload);

      const currentTenant = context.rootState.tenant.current as Tenant;
      const activeReportGroups = currentTenant.reportGroups.filter((r) => r.active.all);

      for (const item of activeReportGroups) {
        context.commit('setReportGroupInactiveForAll', item.id);
      }

      const match = currentTenant.reportGroups.find((rg) => rg.id === payload.id);

      if (match !== undefined) {
        context.commit('setReportGroupActiveForAll', match.id);
      }
    } catch (e) {
    }
  }

  public setCurrentDocumentLibraryReportGroupAsync = async (context: ActionContext<State, any>, payload: { tenantId: number, tenantName: string, id: number, name: string }) => {
    try {
      const currentUser = context.rootGetters['user/current'] as { id: number, email: string, name: string, currentTenantId: number } | null;
      await userApi.updateActiveDocumentLibraryReportGroupAsync(currentUser!.id, payload);

      const currentTenant = context.rootState.tenant.current as Tenant;
      const activeReportGroups = currentTenant.reportGroups.filter((r) => r.active.documentLibrary);

      for (const item of activeReportGroups) {
        context.commit('setReportGroupInactiveForDocumentLibrary', item.id);
      }

      const match = currentTenant.reportGroups.find((rg) => rg.id === payload.id);

      if (match !== undefined) {
        context.commit('setReportGroupActiveForDocumentLibrary', match.id);
      }
    } catch (e) {
    }
  }

  public setCurrentConfigurationReportGroupAsync = async (context: ActionContext<State, any>, payload: { tenantId: number, tenantName: string, id: number, name: string }) => {
    try {
      const currentUser = context.rootGetters['user/current'] as { id: number, email: string, name: string, currentTenantId: number } | null;
      await userApi.updateActiveConfigurationReportGroupAsync(currentUser!.id, payload);

      const currentTenant = context.rootState.tenant.current as Tenant;
      const activeReportGroups = currentTenant.reportGroups.filter((r) => r.active.configuration);

      for (const item of activeReportGroups) {
        context.commit('setReportGroupInactiveForConfiguration', item.id);
      }

      const match = currentTenant.reportGroups.find((rg) => rg.id === payload.id);

      if (match !== undefined) {
        context.commit('setReportGroupActiveForConfiguration', match.id);
      }
    } catch (e) {
    }
  }

  public setCurrentReportGroupValuationDateAsync = async (context: ActionContext<State, any>, payload: { tenantId: number, tenantName: string, id: number, name: string, valuationDate: string }) => {
    try {
      const currentUser = context.rootGetters['user/current'] as { id: number, email: string, name: string, currentTenantId: number } | null;
      await userApi.updateActiveReportGroupValuationDateAsync(currentUser!.id, payload);
    } catch (e) {
    }
  }

  public setCurrentReportGroupEntityIdsAsync = async (context: ActionContext<State, any>, payload: { tenantId: number, tenantName: string, id: number, name: string, entityIds: string }) => {
    try {
      const currentUser = context.rootGetters['user/current'] as { id: number, email: string, name: string, currentTenantId: number } | null;
      await userApi.updateActiveReportGroupEntityIdsAsync(currentUser!.id, payload);
    } catch (e) {
    }
  }
}
