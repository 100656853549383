<template>
  <b-modal
    v-model="visible"
    ok-variant="danger"
    ok-title="Delete"
    cancel-variant="link"
    centered
    :title="modalTitle"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    size="sm"
    :ok-disabled="submitting"
    @ok.prevent="onOk"
    @cancel="onCancel"
    @close="onClose"
    footer-class="borderstyle"
    header-class="borderstyle"
  >
    <span>{{ modalMsg }}</span>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DeleteTeamDialog extends Vue {
  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ type: String, required: true }) public team!: string;

  private visible: boolean = false;
  private submitting: boolean = false;

  get modalTitle(): string {
    return `Delete team '${this.team}'`;
  }

  get modalMsg(): string {
    return `Are you sure that you want to delete the team '${this.team}' ?`;
  }

  onOk(): void {
    this.submitting = true;
    this.$emit('ok', {
      name: this.team,
    });
  }

  onCancel(): void {
    this.$emit('cancel');
  }

  onClose(): void {
    this.$emit('close');
  }

  @Watch('show', { immediate: true })
  onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>

<style>
.borderstyle {
  border: none !important;
}
</style>
