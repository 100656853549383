import { Api } from '@/api/api';
import { WorkflowResponse } from './responses/workflow/workflow-response';
import { WorkflowPageResponse } from './responses/workflow/workflow-page-response';
import { WorkflowReportActivityLogResponse } from './responses/workflow/workflow-report-activity-log-response';
import { WorkflowReportIssuesResponse } from './responses/workflow/workflow-report-issues-response';
import { WorkflowReportContentResponse } from './responses/workflow/workflow-report-content-response';
import { WorkflowReportDistributionsResponse } from './responses/workflow/workflow-report-distributions-response';
import { FindWorkflowResponse } from './responses/workflow/find-workflow-response';
import { WorkflowReportResponse } from './responses/workflow/workflow-report-response';

export class WorkflowApi extends Api {
  public constructor() {
    super();
  }

  public getWorkflowForReportGroupAsync(reportGroupId: number): Promise<WorkflowResponse> {
    return this.getAsync<WorkflowResponse>(`v1/workflow/${reportGroupId}`);
  }

  public getWorkflowPageAsync(reportGroupId: number, valuationDate: string | Date, status: string | number, page: number, pageSize: number, entityIds?: string | null, filter?: string | null): Promise<WorkflowPageResponse> {
    return this.getAsync<WorkflowPageResponse>(`v1/workflow/${reportGroupId}/report`, { valuationDate, status, page, pageSize, filter, entityIds });
  }

  public getWorkflowReportAsync(reportGroupId: number, reportId: number): Promise<WorkflowReportResponse> {
    return this.getAsync<WorkflowReportResponse>(`v1/workflow/${reportGroupId}/report/${reportId}`);
  }

  public getWorkflowReportActivityAsync(workflowReportId: number, reportGroupId: number): Promise<WorkflowReportActivityLogResponse> {
    return this.getAsync<WorkflowReportActivityLogResponse>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/activity`);
  }

  public approveWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/approve`, { comments: comments });
  }

  public rejectWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/reject`, { comments: comments });
  }

  public flagWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null, flagColour: number): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/flag`, { comments: comments, flagColour: flagColour });
  }

  public editFlagWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null, flagColour: number): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/edit-flag`, { comments: comments, flagColour: flagColour });
  }

  public unflagWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/unflag`, { comments: comments });
  }

  public sendWorkflowReportDelayedCommentaryAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/send-delayed-commentary`, { comments: comments });
  }

  public deleteWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.deleteAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}`, { comments: comments });
  }

  public recheckWorkflowReportDataAsync(workflowReportId: number, reportGroupId: number, comments: string | null, reset: boolean): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/recheck`, { comments: comments, resetIgnoredIssues: reset });
  }

  public returnWorkflowReportForApprovalOneAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/return`, { approval: 'One', comments: comments });
  }

  public returnWorkflowReportForApprovalTwoAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/return`, { approval: 'Two', comments: comments });
  }

  public returnWorkflowReportForApprovalThreeAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/return`, { approval: 'Three', comments: comments });
  }

  public returnWorkflowReportForApprovalFourAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/return`, { approval: 'Four', comments: comments });
  }

  public reRenderWorkflowReportAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/render`, { comments: comments });
  }

  public reAquireWorkflowReportCommentaryAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/reaquire-commentary`, { comments: comments });
  }

  public cancelWorkflowReportCommentaryReaquisitionAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.deleteAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/reaquire-commentary`, { comments: comments });
  }

  public resendWorkflowReportCommentaryEmailAsync(workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/commentary/email`, { comments: comments, type: type });
  }

  public resendWorkflowReportApprovalEmailAsync(workflowReportId: number, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/approval/email`, { comments: comments, type: type });
  }

  public releaseWorkflowReportDraftDataAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/draft`, { comments: comments });
  }

  public getWorkflowReportIssuesAsync(workflowReportId: number, reportGroupId: number): Promise<WorkflowReportIssuesResponse> {
    return this.getAsync<WorkflowReportIssuesResponse>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/issues`);
  }

  public getWorkflowReportContentAsync(workflowReportId: number, reportGroupId: number): Promise<WorkflowReportContentResponse> {
    return this.getAsync<WorkflowReportContentResponse>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/content`);
  }

  public getWorkflowReportDistributionAsync(workflowReportId: number, reportGroupId: number): Promise<WorkflowReportDistributionsResponse> {
    return this.getAsync<WorkflowReportDistributionsResponse>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/distribution`);
  }

  public ignoreWorkflowReportIssueAsync(issueId: number, workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/issues/${issueId}/ignore`, { comments: comments });
  }

  public unignoreWorkflowReportIssueAsync(issueId: number, workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/issues/${issueId}/unignore`, { comments: comments });
  }

  public downloadWorkflowReportAsync(workflowReportId: number, reportGroupId: number, isPreview: boolean): Promise<Blob> {
    return this.getFileAsync(`v1/workflow/${reportGroupId}/report/${workflowReportId}/download/${isPreview}`);
  }

  public generateWorkflowReportPreviewUrlAsync(workflowReportId: number, reportGroupId: number, isPreview: boolean): Promise<string> {
    return this.getAsync<string>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/generate-preview-url/${isPreview}`);
  }

  public findWorkflowReportAsync(workflowReportId: string | number): Promise<FindWorkflowResponse> {
    return this.getAsync<FindWorkflowResponse>(`v1/workflow/report/${workflowReportId}`);
  }

  public releasePendingWorkflowReportAsync(workflowReportId: string | number, reportGroupId: number): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/release`, null);
  }

  public releaseAllPendingWorkflowReportsAsync(reportGroupId: number, stateId: number, valuationDate: string | Date): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/release`, { valuationDate, stateId });
  }

  public runReplacementForWorkflowReportAsync(workflowReportId: number, useApprovalWorkflow: number, useStandardDistribution: boolean, includeCommentary: boolean, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/replace`, { comments: comments, useApprovalWorkflow, useStandardDistribution, includeCommentary });
  }

  public scheduleWorkflowReportBatchDistribution(batchId: number, reportGroupId: number, valuationDate: string | Date, runAt: string | Date): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/distribute`, { distributionBatchId: batchId, valuationDate, runAt });
  }

  public ignoreCompletedWorkflowReportNewDataIssuesAsync(workflowReportId: number, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/report/${workflowReportId}/ignore`, { comments: comments });
  }

  public bulkApproveWorkflowReportAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-approve`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkRejectWorkflowReportAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-reject`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkFlagWorkflowReportAsync(workflowReportIds: string, reportGroupId: number, comments: string | null, flagColour: number): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-flag`, { comments: comments, flagColour: flagColour, reportIds: workflowReportIds });
  }

  public bulkUnflagWorkflowReportAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-unflag`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkSendWorkflowReportDelayedCommentaryAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-send-delayed-commentary`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkDeleteWorkflowReportAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.deleteAsync<void>(`v1/workflow/${reportGroupId}/reports`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkRecheckWorkflowReportDataAsync(workflowReportIds: string, reportGroupId: number, comments: string | null, reset: boolean): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-recheck`, { comments: comments, resetIgnoredIssues: reset, reportIds: workflowReportIds });
  }

  public bulkReturnWorkflowReportForApprovalOneAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-return`, { approval: 'One', comments: comments, reportIds: workflowReportIds });
  }

  public bulkReturnWorkflowReportForApprovalTwoAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-return`, { approval: 'Two', comments: comments, reportIds: workflowReportIds });
  }

  public bulkReturnWorkflowReportForApprovalThreeAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-return`, { approval: 'Three', comments: comments, reportIds: workflowReportIds });
  }

  public bulkReturnWorkflowReportForApprovalFourAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-return`, { approval: 'Four', comments: comments, reportIds: workflowReportIds });
  }

  public bulkReRenderWorkflowReportAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-render`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkReAquireWorkflowReportCommentaryAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-reaquire-commentary`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkCancelWorkflowReportCommentaryReaquisitionAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.deleteAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-reaquire-commentary`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkResendWorkflowReportCommentaryEmailAsync(workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/commentary/bulk-email`, { comments: comments, type: type, reportIds: workflowReportIds });
  }

  public bulkResendWorkflowReportApprovalEmailAsync(workflowReportIds: string, reportGroupId: number, type: 'Email' | 'EmailForDeadline', comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/approval/bulk-email`, { comments: comments, type: type, reportIds: workflowReportIds });
  }

  public bulkReleaseWorkflowReportDraftDataAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-draft`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkIgnoreCompletedWorkflowReportNewDataIssuesAsync(workflowReportIds: string, reportGroupId: number, comments: string | null): Promise<void> {
    return this.postAsync<void>(`v1/workflow/${reportGroupId}/reports/bulk-ignore`, { comments: comments, reportIds: workflowReportIds });
  }

  public bulkDownloadWorkflowReportsAsync(reportGroupId: number, zipFileName: string, files: { fileName: string, filePath: string }[]) : Promise<Blob> {
    return this.getFileAsPostAsync(`v1/workflow/${reportGroupId}/reports/bulk-download`, { zipFileName: zipFileName, files: files });
  }
}
