<template>
  <b-table class="document-activity-table"
           striped
           hover
           :sticky-header="headerStyle"
           :fields="fields"
           :items="filteredResults"
           :busy="loading"
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
  >
    <template v-slot:head(description)="data">
      <span>{{ data.label }}<b-button title="Clear filter" size="sm" variant="link" v-if="descriptionFilter" @click.stop="onDescriptionFilterClear">(clear filter)</b-button></span>
      <span @click.stop="onShowFilter">
        <svg width="1rem" height="1em" viewBox="0 3 14 14" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-icon bi bi-filter mx-auto">
          <g data-v-11c9e491="">
            <path fill-rule="evenodd" d="M7.5 13a.5.5 0 01.5-.5h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h8a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h12a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path>
          </g>
        </svg>
      </span>
      <b-form-input class="mt-3" v-if="showFilter" v-model="descriptionFilter" @input="onDescriptionFiltered" />
    </template>

    <template v-slot:cell(description)="{ item }">
      <span>{{ item.description }}</span>
      <b-link
        v-if="item.links.report.length > 0"
        @click="onViewReplacementReportClicked(item)"
        class="vertical-align d-inline-flex align-items-center"
      >
        <b-icon icon="link45deg" scale="1.4" class="ml-2" />
      </b-link>
      <b-link
        v-else-if="showDocumentLink(item)"
        @click="onViewLinkedDocumentClicked(item)"
        class="vertical-align d-inline-flex align-items-center"
      >
        <b-icon icon="link45deg" scale="1.4" class="ml-2" />
      </b-link>
    </template>

    <template v-slot:cell(timestamp)="{ item }">
      {{ item.timestamp | datetime }}
    </template>

    <template v-slot:cell(status)="{ item }">
      <document-activity-status :item="item" />
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkflowReportActivityResponse } from '@/api/responses/workflow/workflow-report-activity-response';
import DocumentActivityStatus from './document-activity-status.vue';
import { namespace } from 'vuex-class';
import { DateTime } from 'luxon';
import debounce from 'lodash.debounce';
import { DocumentActivityResponse } from '../../api/responses/document-library/document-activity-response';
import { FindWorkflowResponse } from '@/api/responses/workflow/find-workflow-response';
import { kebabcase } from '@/utilities/text.utils';
import { Tenant } from '@/store/tenant/state';
import { date } from '@/filters/date';

const tenantModule = namespace('tenant');
const environmentModule = namespace('environment');

@Component({
  components: {
    DocumentActivityStatus,
  },
})
export default class DocumentActivityTable extends Vue {
  private sortBy: string | null = null;
  private sortDesc: string | null = null;
  private descriptionFilter: string | null = null;
  private showFilter = false;
  @Prop({ type: Array, required: true }) public activity!: Array<WorkflowReportActivityResponse>;
  @Prop({ type: Boolean, required: true }) public loading!: boolean;
  @environmentModule.Getter('current') public environment!: { environment: string, version: string | null, runtime: string } | null;
  @tenantModule.Getter private tenants!: Array<Tenant>;

  public get fields(): Array<{ key: string, label: string, class?: Array<string> | string, sortable?: boolean, formatter?: Function }> {
    return [
      { key: 'status', label: '', class: ['col-auto'] },
      { key: 'description', label: 'Description', class: ['col-auto'] },
      { key: 'timestamp', label: 'Date', class: ['col-3'], sortable: true, formatter: (value: string | null) => { if (value === null) { return null; } return DateTime.fromISO(value).toLocal().toJSDate(); } }
    ];
  }

  public get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  public get filteredResults(): Array<WorkflowReportActivityResponse> {
    if (this.activity == null || this.activity.length < 1) {
      return [];
    }

    if (!this.descriptionFilter) {
      return this.activity;
    }

    return this.activity
      .filter((a) => a.description!.toLowerCase().includes(this.descriptionFilter!.trim().toLowerCase()));
  }

  private onShowFilter(): void {
    this.showFilter = !this.showFilter;
  }

  private onDescriptionFiltered = debounce(this.onDescriptionFilteredInternal, 250);
  private onDescriptionFilteredInternal(value: string | null) {
    this.descriptionFilter = value;
  }

  private onDescriptionFilterClear(): void {
    this.descriptionFilter = null;
    this.showFilter = false;
  }

  private async onViewReplacementReportClicked(item: DocumentActivityResponse): Promise<void> {
    const reportId = item.links.report[0];
    const result = await this.$store.dispatch('workflow/findWorkflowReportAsync', reportId) as FindWorkflowResponse;

    if (result === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    const tenant = this.tenants.find((t) => t.id === result.tenantId) || null;

    if (tenant === null) {
      // TODO(Dan): Maybe we should show an error toast here?
      return;
    }

    await this.$router.push({ name: 'workflow', params: { tenant: kebabcase(tenant.name), reportGroup: kebabcase(result.reportGroup), valuationDate: kebabcase(date(result.displayDate, result.dateFormat)), option: kebabcase(result.state), reportId: reportId.toString() } });
  }

  private showDocumentLink(item: DocumentActivityResponse): boolean {
    if (item.links.document.length === 0) {
      return false;
    }

    const { documentId } = this.$route.params;
    if (documentId !== undefined) {
      if (documentId === item.links.document[0]) {
        return false;
      }
    }

    return true;
  }

  private async onViewLinkedDocumentClicked(item: DocumentActivityResponse): Promise<void> {
  }
}
</script>

<style scoped>
.vertical-align {
  vertical-align: middle;
}
</style>
