export class State {
  pages: Array<WorkflowPage> = [];
  current: Workflow | null = null;
  selectedReportId: number | null = null;
  selectedValuationDate: WorkflowDate | null = null;
  currentOption: WorkflowOption | null = null;
  loadingWorkflowReports = false;
  downloadingReports = false;
  filters: WorkflowFilters = { id: null, description: null };
}

export interface Workflow {
  reportGroupId: number;
  hasCommentary: boolean;
  itemsPerPage: number;
  supportNoCommentaryReplacements: boolean;
  displayDeadlineBusinessDay: boolean;
  hasBatchActionsAvailable: boolean;
  hasPresortingEnabled: boolean;
  approvalStages: number;
  dateFormat: string;
  entityName: string;
  selectedEntityIDs: string;
  dates: Array<WorkflowDate>;
  options: Array<WorkflowOption>;
  flags: Array<WorkflowFlag>;
  entities: Array<WorkflowEntity>;
}

export interface WorkflowDate {
  valuationDate: string;
  displayDate: string;
  options: { [key: string]: number | undefined };
  active: boolean;
}

export interface WorkflowPage {
  page: number;
  pageSize: number;
  status: string;
  statusId: number;
  workflows: Array<WorkflowReport>;
  reportGroupId: number;
}

export interface WorkflowReport {
  commentary: string | null;
  fileName: string | null;
  id: number;
  name: string;
  numberOfPages: number;
  fileTypePreview: string | null;
  filenamePreview: string | null;
  filenameDownload: string | null;
  fileTypeDownload: string | null;
  pageStatus: string;
  replaced: boolean;
  flagged: boolean;
  flagColour: number;
  flagReason: string | null;
  supportTicket: boolean;
  supportTicketComment: string | null;
  status: string | number;
  renderingStatus: WorkflowReportRenderingStatus | number;
  completedDate: string | null;
  lastRenderedDate: string | null;
  deadlineBusinessDay: number;
  deadlineDate: string | null;
  createdDate: string;
  archivedDate: string | null;
  hasDataIssues: boolean;
  hasCommentary: boolean;
  hasDistribution: boolean;
  remainingDistributionBatches: number;
  actions: { [key: string]: boolean },
  tooltips: { [key in keyof Partial<WorkflowReport>]: string | null | undefined }
  active: boolean;
}

export interface WorkflowOption {
  id: number;
  order: number;
  name: string;
  hideIfEmpty: boolean;
  active: boolean;
}

export interface WorkflowFlag {
  flagNumber: number;
  name: string;
  rgbColour: string;
  hexColour: string;
}

export interface WorkflowEntity {
  entityId: number;
  name: string;
}

export type WorkflowReportRenderingStatus = 'Blank' |
  'DataCheckWaiting' |
  'DataCheckRunning' |
  'RenderPending' |
  'RenderWaiting' |
  'RenderRunning' |
  'DistributionWaiting' |
  'DistributionRunning' |
  'AwaitingBatchDistribution' |
  'Error' | number;

export interface WorkflowReportIssue {
  canBeIgnored: boolean;
  canBeUnignored: boolean;
  commentaryReceivedDate: string | null;
  description: string;
  id: number;
  ignored: boolean;
  ignoredBy: string | null;
  ignoredDate: string | null;
  reportId: number;
  type: string;
  links: WorkflowReportIssueLink;
  blockingApproval: number | null;
}

export interface WorkflowReportIssueLink {
  languages: Array<LanguageLink>;
}

export interface LanguageLink {
  language: string | null;
  languageId: number;
  phrase: string | null;
}

export interface WorkflowFilters {
  id: string | null;
  description: string | null;
}
