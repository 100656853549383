import { render, staticRenderFns } from "./document-activity-table.vue?vue&type=template&id=61ebd264&scoped=true"
import script from "./document-activity-table.vue?vue&type=script&lang=ts"
export * from "./document-activity-table.vue?vue&type=script&lang=ts"
import style0 from "./document-activity-table.vue?vue&type=style&index=0&id=61ebd264&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ebd264",
  null
  
)

export default component.exports