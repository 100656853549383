import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State, SupportConfiguration, SupportTicketPage, SupportTicketAttachment, SupportTicketActivity, SupportTicketMessages, SupportTicketDetails, SupportTicket, SupportTicketFilters } from '@/store/support/state';

export class Mutations implements MutationTree<State> {
  [key: string]: (state: State, payload: any) => any;

  public setCurrent = (state: State, data: SupportConfiguration) => {
    state.current = data;
  }

  public setLoading = (state: State, value: boolean) => {
    state.loading = value;
  }

  public addTicketPage = (state: State, page: SupportTicketPage) => {
    Vue.set(state.pages, page.page - 1, page);
  }

  public clearPages = (state: State) => {
    state.pages = [];
  }

  public setCurrentStatus = (state: State, index: number) => {
    state.status = index;
  }

  public setCurrentTicket = (state: State, ticket: SupportTicketDetails) => {
    state.ticket = ticket;
  }

  public setCurrentTicketAttachments = (state: State, attachments: Array<SupportTicketAttachment>) => {
    state.attachments = attachments;
  }

  public setCurrentTicketMessages = (state: State, messages: SupportTicketMessages) => {
    state.messages = messages;
  }

  public setCurrentTicketNotes = (state: State, notes: SupportTicketMessages) => {
    state.notes = notes;
  }

  public setCurrentTicketActivity = (state: State, activity: SupportTicketActivity) => {
    state.activity = activity;
  }

  public pushTicketToPage = (state: State, payload: { ticket: SupportTicket, pageNumber: number }) => {
    const page = state.pages.find((p) => p.page === payload.pageNumber);

    if (page === undefined) {
      return;
    }

    page.tickets.unshift(payload.ticket);
    page.pageSize += 1;
  }

  public updateStatusCount = (state: State, payload: { ticket: SupportTicket, status: string }) => {
    if (state.current === null) {
      return;
    }

    const status = state.current.status.find((s) => s.name.toLowerCase() === payload.status.toLowerCase()) || null;

    if (status === null) {
      return;
    }

    status.count += 1;
  }

  public setOpeningTicketCorrelationId = (state: State, payload: string | null) => {
    state.openingTicketCorrelationId = payload;
  }

  public setOpeningTicketId = (state: State, payload: number | null) => {
    state.openingTicketId = payload;
  }

  public setFilters = (state: State, filters: SupportTicketFilters) => {
    state.filters = filters;
  }
}
